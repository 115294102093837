import { AbsoluteCenter, Flex, Text, Tooltip } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedItems } from "../store/slices/generalSlice";
import { toast } from "react-toastify";
import { satbox } from "../utils/box/newSat";

function Hexagon(props: any) {
  const satNumber = satbox(props?.number);
  const dispatch = useDispatch();
  const { selectedItems } = useSelector((state: any) => state.general);
  const firstNumber = props?.number?.[0];
  const last2Numbers = props?.number?.slice(-2);
  return (
    <Tooltip
      pointerEvents="all"
      hasArrow
      onClick={(e) => {
        if (!e.defaultPrevented) {
          window.open(`/details/${props?.number}`, "_blank");
        }
      }}
      onTouchEnd={(e) => {
        if (!e.defaultPrevented) {
          window.open(`/details/${props?.number}`, "_blank");
        }
      }}
      textDecoration="underline"
      label={"view " + satNumber?.value?.toString()}
      closeDelay={1000}
      openDelay={700}
      // minW="124px"
      zIndex="1000"
      bg="#8B69C5"
      borderRadius="8px"
      gap="10px"
      cursor="pointer"
      marginTop="-10px"
    >
      <Flex
        ref={props.scrollRef}
        display="inline-block"
        pos="relative"
        key={props.idx}
        justify="center"
        align="center"
        height={`calc(${props.s}px * 1.1547)`}
        width={`${props.s}px`}
        margin={`${props.m}px`}
        marginBottom={`calc(${props.m}px - ${props.s}px*0.2885)`}
        bg={
          props.checkIsMinted(props.idx)
            ? "#8D7712"
            : selectedItems?.includes(props?.number)
            ? "linear-gradient(97deg, #CD23E9 44.31%, #AC00FD 90.84%)"
            : "#FFC700"
        }
        clipPath="polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%)"
        cursor="pointer"
        onClick={(e) => {
          if (!e.defaultPrevented) {
            if (
              selectedItems?.length < 500 &&
              !props.checkIsMinted(props.idx)
            ) {
              dispatch(setSelectedItems(props?.number));
            } else if (props?.checkIsMinted(props?.idx)) {
              toast.warn("The block has been minted.");
            } else {
              toast.warn("You have reached the maximum limit per mint");
            }
          }
        }}
        onTouchEnd={(e) => {
          if (!e.defaultPrevented) {
            if (
              selectedItems?.length < 500 &&
              !props.checkIsMinted(props.idx)
            ) {
              dispatch(setSelectedItems(props?.number));
            } else if (props?.checkIsMinted(props?.idx)) {
              toast.warn("The block has been minted.");
            } else {
              toast.warn("You have reached the maximum limit per mint");
            }
          }
        }}
      >
        <AbsoluteCenter pos="relative">
          <Text
            color="#fff"
            fontSize={Number(props?.number) > 20000 ? "8px" : "10px"}
            fontWeight="600"
          >
            {/* {Number(props?.number) > 10000
              ? firstNumber + "**" + last2Numbers
              : props.number} */}
            {props?.number}
          </Text>
        </AbsoluteCenter>
      </Flex>
    </Tooltip>
  );
}

export default Hexagon;
