import { Box, useDisclosure } from "@chakra-ui/react";
import MyItems from "../components/MyItems";

function MyItemsPage({ onBonusOpen }: any) {
  return (
    <Box>
      <MyItems onBonusOpen={onBonusOpen} />
    </Box>
  );
}

export default MyItemsPage;
