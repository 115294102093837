import { useMutation, useQuery } from "@tanstack/react-query";
import { client } from "./api-client";

export const useGetStatus = () =>
  useQuery(["getStatus"], async () => {
    const response = await client(`sats/status`);
    return response;
  });

export const useGetRarity = () => {
  return useMutation(async ({ rarity, start }: any) => {
    const response = await client(`sats/${rarity}/${start}`, {
      method: "GET",
    });
    return response;
  });
};

export const useGetUnclaimed = () => {
  return useMutation(async ({ start }: any) => {
    if (Number(start) === 0) {
      const response = await client(`sats/unclaimed/0`, {
        method: "GET",
      });
      return response;
    } else {
      const response = await client(`sats/unclaimed/${start}`, {
        method: "GET",
      });
      return response;
    }
  });
};

export const useGetSat = () => {
  return useMutation(async ({ sat }: any) => {
    const response = await client(`sats/${sat}`, {
      method: "GET",
    });
    return response;
  });
};

export const useGetNetworkFee = () =>
  useQuery(["getNetworkFee"], async () => {
    const response = await client(`sats/fees`);
    return response;
  });

export const useGetPaymentInfo = () => {
  return useMutation(async (paylod: any) => {
    const response = await client(`sats/mint`, {
      method: "POST",
      data: paylod,
    });
    return response;
  });
};

export const usePaymentInfo = () => {
  return useMutation(async ({ id, txid }: any) => {
    const response = await client(`sats/pay?id=${id}&&txid=${txid}`, {
      method: "GET",
    });
    return response;
  });
};

export const useGetHolderRanks = (start: number) =>
  useQuery(["getHolderRanks"], async () => {
    const response = await client(`sats/holders/rank/${start}`);
    return response;
  });

export const useSelectRandom = () => {
  return useMutation(async (random: number) => {
    const response = await client(`sats/random/${random}`, {
      method: "GET",
    });
    return response;
  });
};

export const useGetMyMints = () => {
  return useMutation(async (address: string) => {
    const response = await client(`sats/mint/${address}`, {
      method: "GET",
    });
    return response;
  });
};

export const useClaimBonus = () => {
  return useMutation(async (payload: any) => {
    const response = await client(`sats/bonus/${payload?.address}`, {
      method: "POST",
      data: payload?.payload,
    });
    return response;
  });
};

export const useRefereshSats = () => {
  return useMutation(async (payload: any) => {
    const response = await client(
      `sats/mint?id=${payload?.id}&txid=${payload?.txId}`,
      {
        method: "POST",
      }
    );
    return response;
  });
};
