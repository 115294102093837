import { QueryCache } from "@tanstack/react-query";
 
const apiURL = "https://api.runebox.app";

const queryCache = new QueryCache({
  onError: (error) => {
    console.log(error);
  },
  onSuccess: (data) => {
    console.log(data);
  },
});

async function client(
  endpoint: any,
  { data, headers: customHeaders, ...customConfig }: any = {}
) {
  const config = {
    method: data ? "POST" : "GET",
    body: data instanceof FormData ? data : JSON.stringify(data),
    headers: {
      "x-api-key": "1ceae295-37c7-4fa9-b6b1-24187a605608",
      ...(!data || data instanceof FormData
        ? {}
        : { "Content-Type": "application/json;charset=utf-8" }),
      ...customHeaders,
    },
    ...customConfig,
  };

  return await window
    .fetch(`${apiURL}/${endpoint}`, config)
    .then(async (response) => {
      if (response.status === 401) {
        queryCache.clear();
        const errorData = await response.json();
        const error = new Error(errorData.message || "Unauthorized");
        return Promise.reject(error);
      }
      if (response.ok) {
        const jsonData = await response.json();
        return jsonData;
      } else {
        const jsonData = await response.json();
        return Promise.reject(jsonData);
      }
    });
}

export { client };
