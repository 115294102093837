import {
  Avatar,
  Box,
  Circle,
  Flex,
  Image,
  Progress,
  Text,
} from "@chakra-ui/react";
import Triangle from "../assets/images/triangle.svg";
import UEFABg from "../assets/images/uefa-bg.svg";
import PolygonArrow from "../assets/images/polygon-arrow.svg";
import TrophyIco from "../assets/images/trophy.svg";
import React from "react";

function UEFAMapView({
  setRuneCart,
  runeCart,
  setSearchByName,
  searchByName,
}: any) {
  return (
    <Box px="20px">
      <Flex align="center" justify="flex-start" wrap="wrap" gap="46px">
        {new Array(6).fill(1).map((_, index) => {
          return <GroupCard key={index} group={index} />;
        })}
      </Flex>
      <Box bgImage={UEFABg} bgRepeat="no-repeat" bgPos="top">
        <Flex mt="30px" align="center" gap="12px">
          <Flex
            align="center"
            p="10px 16px"
            bg="#1E2930"
            borderRadius="6px"
            gap="6px"
          >
            <Circle bg="#130D25" size="20px" />
            <Text fontSize="12px" color="#fff" fontWeight="600">
              Round Off Matches
            </Text>
          </Flex>
          <Flex
            align="center"
            p="10px 16px"
            bg="#1E2930"
            borderRadius="6px"
            gap="6px"
          >
            <Circle bg="#170943" size="20px" />
            <Text fontSize="12px" color="#fff" fontWeight="600">
              Quater Finals
            </Text>
          </Flex>
          <Flex
            align="center"
            p="10px 16px"
            bg="#1E2930"
            borderRadius="6px"
            gap="6px"
          >
            <Circle bg="#350A48" size="20px" />
            <Text fontSize="12px" color="#fff" fontWeight="600">
              Finals
            </Text>
          </Flex>
        </Flex>
        <Flex mt="80px" justify="space-between" gap="20px" overflowX="auto">
          <Flex flexDir="column" gap="16px">
            <Flex align="center" gap="44px" pos="relative">
              <Flex flexDir="column" gap="16px">
                <Flex
                  p="16px"
                  borderRadius="10px"
                  bg="#130D25"
                  border="1px solid #272525"
                  align="center"
                  justify="center"
                  gap="20px"
                >
                  <Flex align="start" gap="8px" flexDir="column">
                    <Circle
                      bg="linear-gradient(129deg, #171325 0%, #3C3651 112.96%)"
                      p="8px"
                      size="30px"
                    >
                      <Avatar boxSize="20px" />
                    </Circle>
                    <Text fontSize="12px" color="#fff" whiteSpace="nowrap">
                      1st: B
                    </Text>
                  </Flex>
                  <Text
                    color="rgba(255, 255, 255, 0.60)"
                    fontSize="12px"
                    fontWeight="400"
                  >
                    VS
                  </Text>
                  <Flex align="end" gap="8px" flexDir="column">
                    <Circle
                      bg="linear-gradient(129deg, #171325 0%, #3C3651 112.96%)"
                      p="8px"
                      size="30px"
                    >
                      <Avatar boxSize="20px" />
                    </Circle>
                    <Text fontSize="12px" color="#fff" whiteSpace="nowrap">
                      3rd: A/D/E/F
                    </Text>
                  </Flex>
                </Flex>
                <Flex
                  p="16px"
                  borderRadius="10px"
                  bg="#130D25"
                  border="1px solid #272525"
                  align="center"
                  justify="center"
                  gap="20px"
                >
                  <Flex align="start" gap="8px" flexDir="column">
                    <Circle
                      bg="linear-gradient(129deg, #171325 0%, #3C3651 112.96%)"
                      p="8px"
                      size="30px"
                    >
                      <Avatar boxSize="20px" />
                    </Circle>
                    <Text fontSize="12px" color="#fff" whiteSpace="nowrap">
                      1st: A
                    </Text>
                  </Flex>
                  <Text
                    color="rgba(255, 255, 255, 0.60)"
                    fontSize="12px"
                    fontWeight="400"
                  >
                    VS
                  </Text>
                  <Flex align="end" gap="8px" flexDir="column">
                    <Circle
                      bg="linear-gradient(129deg, #171325 0%, #3C3651 112.96%)"
                      p="8px"
                      size="30px"
                    >
                      <Avatar boxSize="20px" />
                    </Circle>
                    <Text fontSize="12px" color="#fff" whiteSpace="nowrap">
                      2nd: C
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
              <Image
                src={PolygonArrow}
                pos="absolute"
                left="50%"
                transform="translate(-50%)"
                marginLeft="-2px"
                zIndex="-1"
              />
              <Flex
                p="16px"
                borderRadius="10px"
                bg="#170943"
                align="center"
                justify="center"
                gap="20px"
                maxH="116px"
              >
                <Flex align="start" gap="8px" flexDir="column">
                  <Circle
                    bg="linear-gradient(129deg, #171325 0%, #3C3651 112.96%)"
                    p="8px"
                    size="30px"
                  >
                    <Avatar boxSize="20px" />
                  </Circle>
                  <Text fontSize="12px" color="#fff">
                    W39
                  </Text>
                </Flex>
                <Text
                  color="rgba(255, 255, 255, 0.60)"
                  fontSize="12px"
                  fontWeight="400"
                >
                  VS
                </Text>
                <Flex align="end" gap="8px" flexDir="column">
                  <Circle
                    bg="linear-gradient(129deg, #171325 0%, #3C3651 112.96%)"
                    p="8px"
                    size="30px"
                  >
                    <Avatar boxSize="20px" />
                  </Circle>
                  <Text fontSize="12px" color="#fff">
                    w37
                  </Text>
                </Flex>
              </Flex>
            </Flex>
            <Flex align="center" justify="end" gap="44px">
              <Flex flexDir="column" gap="16px">
                <Flex
                  p="16px"
                  borderRadius="10px"
                  bg="#350A48"
                  align="center"
                  justify="center"
                  gap="20px"
                >
                  <Flex align="center" gap="8px" flexDir="column">
                    <Circle
                      bg="linear-gradient(129deg, #171325 0%, #3C3651 112.96%)"
                      p="8px"
                      size="30px"
                    >
                      <Avatar boxSize="20px" />
                    </Circle>
                    <Text fontSize="12px" color="#fff">
                      W45
                    </Text>
                  </Flex>
                </Flex>
                <Text
                  fontSize="10px"
                  fontWeight="600"
                  textAlign="center"
                  color="#fff"
                >
                  Semi - Final
                </Text>
                <Flex
                  p="16px"
                  borderRadius="10px"
                  bg="#350A48"
                  align="center"
                  justify="center"
                  gap="20px"
                >
                  <Flex align="center" gap="8px" flexDir="column">
                    <Circle
                      bg="linear-gradient(129deg, #171325 0%, #3C3651 112.96%)"
                      p="8px"
                      size="30px"
                    >
                      <Avatar boxSize="20px" />
                    </Circle>
                    <Text fontSize="12px" color="#fff">
                      W46
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
              <Flex
                p="16px"
                borderRadius="7px"
                bg="#fff"
                align="center"
                justify="center"
                gap="20px"
                maxH="116px"
                pos="relative"
              >
                <Flex
                  bg="linear-gradient(97deg, #CD23E9 44.31%, #AC00FD 90.84%)"
                  h="23px"
                  w="53px"
                  align="center"
                  justify="center"
                  pos="absolute"
                  top="0"
                  left="0"
                  marginTop="-15px"
                  marginLeft="-10px"
                >
                  <Text color="#fff" fontSize="14px" fontWeight="600">
                    Final
                  </Text>
                  <Image
                    src={Triangle}
                    h="11px"
                    pos="absolute"
                    left="0"
                    mt="33.5px"
                    zIndex="-1"
                  />
                </Flex>
                <Flex align="center" gap="8px">
                  <Circle
                    bg="linear-gradient(129deg, #171325 0%, #3C3651 112.96%)"
                    p="8px"
                    size="30px"
                  >
                    <Avatar boxSize="20px" />
                  </Circle>
                  <Text fontSize="14px" color="#500C6F" fontWeight="600">
                    W49
                  </Text>
                </Flex>
              </Flex>
            </Flex>
            <Flex align="center" gap="44px" pos="relative">
              <Flex flexDir="column" gap="16px">
                <Flex
                  p="16px"
                  borderRadius="10px"
                  bg="#130D25"
                  border="1px solid #272525"
                  align="center"
                  justify="center"
                  gap="20px"
                >
                  <Flex align="start" gap="8px" flexDir="column">
                    <Circle
                      bg="linear-gradient(129deg, #171325 0%, #3C3651 112.96%)"
                      p="8px"
                      size="30px"
                    >
                      <Avatar boxSize="20px" />
                    </Circle>
                    <Text fontSize="12px" color="#fff" whiteSpace="nowrap">
                      1st: F
                    </Text>
                  </Flex>
                  <Text
                    color="rgba(255, 255, 255, 0.60)"
                    fontSize="12px"
                    fontWeight="400"
                  >
                    VS
                  </Text>
                  <Flex align="end" gap="8px" flexDir="column">
                    <Circle
                      bg="linear-gradient(129deg, #171325 0%, #3C3651 112.96%)"
                      p="8px"
                      size="30px"
                    >
                      <Avatar boxSize="20px" />
                    </Circle>
                    <Text fontSize="14px" color="#fff" whiteSpace="nowrap">
                      3rd: A/B/C
                    </Text>
                  </Flex>
                </Flex>
                <Flex
                  p="16px"
                  borderRadius="10px"
                  bg="#130D25"
                  border="1px solid #272525"
                  align="center"
                  justify="center"
                  gap="20px"
                >
                  <Flex align="start" gap="8px" flexDir="column">
                    <Circle
                      bg="linear-gradient(129deg, #171325 0%, #3C3651 112.96%)"
                      p="8px"
                      size="30px"
                    >
                      <Avatar boxSize="20px" />
                    </Circle>
                    <Text fontSize="12px" color="#fff" whiteSpace="nowrap">
                      2nd: D
                    </Text>
                  </Flex>
                  <Text
                    color="rgba(255, 255, 255, 0.60)"
                    fontSize="12px"
                    fontWeight="400"
                  >
                    VS
                  </Text>
                  <Flex align="end" gap="8px" flexDir="column">
                    <Circle
                      bg="linear-gradient(129deg, #171325 0%, #3C3651 112.96%)"
                      p="8px"
                      size="30px"
                    >
                      <Avatar boxSize="20px" />
                    </Circle>
                    <Text fontSize="14px" color="#fff" whiteSpace="nowrap">
                      2nd: E
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
              <Image
                src={PolygonArrow}
                pos="absolute"
                left="50%"
                transform="translate(-50%)"
                marginLeft="7px"
                zIndex="-1"
              />
              <Flex
                p="16px"
                borderRadius="10px"
                bg="#170943"
                align="center"
                justify="center"
                gap="20px"
                maxH="116px"
              >
                <Flex align="start" gap="8px" flexDir="column">
                  <Circle
                    bg="linear-gradient(129deg, #171325 0%, #3C3651 112.96%)"
                    p="8px"
                    size="30px"
                  >
                    <Avatar boxSize="20px" />
                  </Circle>
                  <Text fontSize="14px" color="#fff">
                    W41
                  </Text>
                </Flex>
                <Text
                  color="rgba(255, 255, 255, 0.60)"
                  fontSize="12px"
                  fontWeight="400"
                >
                  VS
                </Text>
                <Flex align="end" gap="8px" flexDir="column">
                  <Circle
                    bg="linear-gradient(129deg, #171325 0%, #3C3651 112.96%)"
                    p="8px"
                    size="30px"
                  >
                    <Avatar boxSize="20px" />
                  </Circle>
                  <Text fontSize="12px" color="#fff">
                    W42
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Image src={TrophyIco} mt="80px" boxSize="650px" />
          <Flex flexDir="column" gap="16px">
            <Flex align="center" gap="44px" pos="relative">
              <Flex
                p="16px"
                borderRadius="10px"
                bg="#170943"
                align="center"
                justify="center"
                gap="20px"
                maxH="116px"
              >
                <Flex align="start" gap="8px" flexDir="column">
                  <Circle
                    bg="linear-gradient(129deg, #171325 0%, #3C3651 112.96%)"
                    p="8px"
                    size="30px"
                  >
                    <Avatar boxSize="20px" />
                  </Circle>
                  <Text fontSize="14px" color="#fff">
                    W43
                  </Text>
                </Flex>
                <Text
                  color="rgba(255, 255, 255, 0.60)"
                  fontSize="12px"
                  fontWeight="400"
                >
                  VS
                </Text>
                <Flex align="end" gap="8px" flexDir="column">
                  <Circle
                    bg="linear-gradient(129deg, #171325 0%, #3C3651 112.96%)"
                    p="8px"
                    size="30px"
                  >
                    <Avatar boxSize="20px" />
                  </Circle>
                  <Text fontSize="12px" color="#fff">
                    W44
                  </Text>
                </Flex>
              </Flex>
              <Image
                src={PolygonArrow}
                pos="absolute"
                right="50%"
                transform=" rotate(180deg) translate(-50%)"
                marginRight="7px"
                zIndex="-1"
              />
              <Flex flexDir="column" gap="16px">
                <Flex
                  p="16px"
                  borderRadius="10px"
                  bg="#130D25"
                  border="1px solid #272525"
                  align="center"
                  justify="center"
                  gap="20px"
                >
                  <Flex align="start" gap="8px" flexDir="column">
                    <Circle
                      bg="linear-gradient(129deg, #171325 0%, #3C3651 112.96%)"
                      p="8px"
                      size="30px"
                    >
                      <Avatar boxSize="20px" />
                    </Circle>
                    <Text fontSize="12px" color="#fff" whiteSpace="nowrap">
                      1st: E
                    </Text>
                  </Flex>
                  <Text
                    color="rgba(255, 255, 255, 0.60)"
                    fontSize="12px"
                    fontWeight="400"
                  >
                    VS
                  </Text>
                  <Flex align="end" gap="8px" flexDir="column">
                    <Circle
                      bg="linear-gradient(129deg, #171325 0%, #3C3651 112.96%)"
                      p="8px"
                      size="30px"
                    >
                      <Avatar boxSize="20px" />
                    </Circle>
                    <Text fontSize="14px" color="#fff" whiteSpace="nowrap">
                      3rd: A/B/C/D
                    </Text>
                  </Flex>
                </Flex>
                <Flex
                  p="16px"
                  borderRadius="10px"
                  bg="#130D25"
                  border="1px solid #272525"
                  align="center"
                  justify="center"
                  gap="20px"
                >
                  <Flex align="start" gap="8px" flexDir="column">
                    <Circle
                      bg="linear-gradient(129deg, #171325 0%, #3C3651 112.96%)"
                      p="8px"
                      size="30px"
                    >
                      <Avatar boxSize="20px" />
                    </Circle>
                    <Text fontSize="12px" color="#fff" whiteSpace="nowrap">
                      1st: D
                    </Text>
                  </Flex>
                  <Text
                    color="rgba(255, 255, 255, 0.60)"
                    fontSize="12px"
                    fontWeight="400"
                  >
                    VS
                  </Text>
                  <Flex align="end" gap="8px" flexDir="column">
                    <Circle
                      bg="linear-gradient(129deg, #171325 0%, #3C3651 112.96%)"
                      p="8px"
                      size="30px"
                    >
                      <Avatar boxSize="20px" />
                    </Circle>
                    <Text fontSize="14px" color="#fff" whiteSpace="nowrap">
                      2nd: F
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
            <Flex align="center" justify="start" gap="44px">
              <Flex
                p="16px"
                borderRadius="7px"
                bg="#fff"
                align="center"
                justify="center"
                gap="20px"
                maxH="116px"
                pos="relative"
              >
                <Flex
                  bg="linear-gradient(97deg, #CD23E9 44.31%, #AC00FD 90.84%)"
                  h="23px"
                  w="53px"
                  align="center"
                  justify="center"
                  pos="absolute"
                  top="0"
                  right="0"
                  marginTop="-15px"
                  marginRight="-10px"
                >
                  <Text color="#fff" fontSize="14px" fontWeight="600">
                    Final
                  </Text>
                  <Image
                    src={Triangle}
                    h="11px"
                    pos="absolute"
                    right="0"
                    mt="33.5px"
                    mr="-1.5px"
                    zIndex="-1"
                    transform="rotate(265deg)"
                  />
                </Flex>
                <Flex align="center" gap="8px">
                  <Circle
                    bg="linear-gradient(129deg, #171325 0%, #3C3651 112.96%)"
                    p="8px"
                    size="30px"
                  >
                    <Avatar boxSize="20px" />
                  </Circle>
                  <Text fontSize="14px" color="#500C6F" fontWeight="600">
                    W50
                  </Text>
                </Flex>
              </Flex>

              <Flex flexDir="column" gap="16px">
                <Flex
                  p="16px"
                  borderRadius="10px"
                  bg="#350A48"
                  align="center"
                  justify="center"
                  gap="20px"
                >
                  <Flex align="center" gap="8px" flexDir="column">
                    <Circle
                      bg="linear-gradient(129deg, #171325 0%, #3C3651 112.96%)"
                      p="8px"
                      size="30px"
                    >
                      <Avatar boxSize="20px" />
                    </Circle>
                    <Text fontSize="12px" color="#fff">
                      W47
                    </Text>
                  </Flex>
                </Flex>
                <Text
                  fontSize="10px"
                  fontWeight="600"
                  textAlign="center"
                  color="#fff"
                >
                  Semi - Final
                </Text>
                <Flex
                  p="16px"
                  borderRadius="10px"
                  bg="#350A48"
                  align="center"
                  justify="center"
                  gap="20px"
                >
                  <Flex align="center" gap="8px" flexDir="column">
                    <Circle
                      bg="linear-gradient(129deg, #171325 0%, #3C3651 112.96%)"
                      p="8px"
                      size="30px"
                    >
                      <Avatar boxSize="20px" />
                    </Circle>
                    <Text fontSize="12px" color="#fff">
                      W48
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
            <Flex align="center" gap="44px" pos="relative">
              <Flex
                p="16px"
                borderRadius="10px"
                bg="#170943"
                align="center"
                justify="center"
                gap="20px"
                maxH="116px"
              >
                <Flex align="start" gap="8px" flexDir="column">
                  <Circle
                    bg="linear-gradient(129deg, #171325 0%, #3C3651 112.96%)"
                    p="8px"
                    size="30px"
                  >
                    <Avatar boxSize="20px" />
                  </Circle>
                  <Text fontSize="14px" color="#fff">
                    W40
                  </Text>
                </Flex>
                <Text
                  color="rgba(255, 255, 255, 0.60)"
                  fontSize="12px"
                  fontWeight="400"
                >
                  VS
                </Text>
                <Flex align="end" gap="8px" flexDir="column">
                  <Circle
                    bg="linear-gradient(129deg, #171325 0%, #3C3651 112.96%)"
                    p="8px"
                    size="30px"
                  >
                    <Avatar boxSize="20px" />
                  </Circle>
                  <Text fontSize="12px" color="#fff">
                    W38
                  </Text>
                </Flex>
              </Flex>
              <Image
                src={PolygonArrow}
                pos="absolute"
                right="50%"
                transform=" rotate(180deg) translate(-50%)"
                marginRight="7px"
                zIndex="-1"
              />
              <Flex flexDir="column" gap="16px">
                <Flex
                  p="16px"
                  borderRadius="10px"
                  bg="#130D25"
                  border="1px solid #272525"
                  align="center"
                  justify="center"
                  gap="20px"
                >
                  <Flex align="start" gap="8px" flexDir="column">
                    <Circle
                      bg="linear-gradient(129deg, #171325 0%, #3C3651 112.96%)"
                      p="8px"
                      size="30px"
                    >
                      <Avatar boxSize="20px" />
                    </Circle>
                    <Text fontSize="12px" color="#fff" whiteSpace="nowrap">
                      1st: C
                    </Text>
                  </Flex>
                  <Text
                    color="rgba(255, 255, 255, 0.60)"
                    fontSize="12px"
                    fontWeight="400"
                  >
                    VS
                  </Text>
                  <Flex align="end" gap="8px" flexDir="column">
                    <Circle
                      bg="linear-gradient(129deg, #171325 0%, #3C3651 112.96%)"
                      p="8px"
                      size="30px"
                    >
                      <Avatar boxSize="20px" />
                    </Circle>
                    <Text fontSize="14px" color="#fff" whiteSpace="nowrap">
                      3rd: D/E/F
                    </Text>
                  </Flex>
                </Flex>
                <Flex
                  p="16px"
                  borderRadius="10px"
                  bg="#130D25"
                  border="1px solid #272525"
                  align="center"
                  justify="center"
                  gap="20px"
                >
                  <Flex align="start" gap="8px" flexDir="column">
                    <Circle
                      bg="linear-gradient(129deg, #171325 0%, #3C3651 112.96%)"
                      p="8px"
                      size="30px"
                    >
                      <Avatar boxSize="20px" />
                    </Circle>
                    <Text fontSize="12px" color="#fff" whiteSpace="nowrap">
                      2nd: A
                    </Text>
                  </Flex>
                  <Text
                    color="rgba(255, 255, 255, 0.60)"
                    fontSize="12px"
                    fontWeight="400"
                  >
                    VS
                  </Text>
                  <Flex align="end" gap="8px" flexDir="column">
                    <Circle
                      bg="linear-gradient(129deg, #171325 0%, #3C3651 112.96%)"
                      p="8px"
                      size="30px"
                    >
                      <Avatar boxSize="20px" />
                    </Circle>
                    <Text fontSize="14px" color="#fff" whiteSpace="nowrap">
                      2nd: B
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
}

const GroupCard = ({ group }: any) => {
  return (
    <Box
      borderRadius="8px"
      bg="linear-gradient(242deg, rgba(40, 51, 54, 0.90) -1.41%, #12171C 57.86%)"
      p="19px"
      minW="200px"
      maxW="200px"
      width="100%"
    >
      <Flex
        w="124px"
        h="38px"
        bg="linear-gradient(265deg, #A913C1 0.82%, #411A53 107.61%)"
        align="center"
        justify="center"
        borderRadius="0px 8px 8px 0px"
        marginLeft="-35px"
        pos="relative"
      >
        <Text fontSize="16px" color="#fff" fontWeight="500">
          Group {group}
        </Text>
        <Image
          src={Triangle}
          alt=""
          pos="absolute"
          left="0"
          top="0"
          ml="-0.5px"
          mt="37px"
          zIndex="-1"
        />
      </Flex>
      <Flex mt="14px" flexDir="column">
        {new Array(4).fill(1).map((_, index) => {
          return (
            <Flex align="center" gap="8px" p="8px">
              <Avatar boxSize="20px" />
              <Flex flexDir="column" gap="4px">
                <Flex justify="space-between" align="flex-end" gap="20px">
                  <Text
                    fontSize="12px"
                    color="#fff"
                    fontWeight="500"
                    fontFamily="Inter"
                  >
                    GERMANY
                  </Text>
                  <Text fontSize="12px" fontFamily="Inter" color="#B3B3B3">
                    32%
                  </Text>
                </Flex>
                <Progress
                  height="4px"
                  borderRadius="10px"
                  value={50}
                  colorScheme="purple"
                />
              </Flex>
            </Flex>
          );
        })}
      </Flex>
    </Box>
  );
};

export default UEFAMapView;
