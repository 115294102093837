
export const getAddressVSize = (address: string) => {

    let inpVsize = 148; // p2pkh
    let outpVsize = 34; // p2pkh
    let overhead = 10
  
    if (address.indexOf('bc1q') == 0 || address.indexOf('tb1q') == 0 || address.indexOf('bcrt1q') == 0) {
        overhead = 10.5
        inpVsize = 68 // p2wpkh
        outpVsize = 31 // p2wpkh
    } else if (address.indexOf('bc1p') == 0 || address.indexOf('tb1p') == 0 || address.indexOf('bcrt1p') == 0) {
        overhead = 10.5
        inpVsize = 57.5 // p2tr
        outpVsize = 43 // p2tr  
    } else if (address.indexOf('2') == 0 || address.indexOf('3') == 0) {
        overhead = 10.5
        inpVsize = 104.5  // p2wsh
        outpVsize = 43 // p2wsh  
    }
    return { overhead, inpVsize, outpVsize }
  }
  
  export function groupRune(mints: number, groupLength = 23) {
    let groupedRunes = [];
  
    // group log
    while (mints > 0) {
        let mintsInGroup = Math.min(mints, groupLength);
        groupedRunes.push({ mints: mintsInGroup });
        mints -= mintsInGroup;
    }
    return groupedRunes;
  }
  
  
  
  export function getRuneCost(mints: number, receive: string, feeRate: number, service_fee: number, balance = 330) {
    let id64x0 = new Array(64).fill('0').join('')
    const utxos = new Array(1).fill(1).map((_, vout) => {
        return {
            txid: id64x0,
            vout,
            value: 10e8
        }
    })
  
  
    // const feeRate = .feeRate
    let inp = getAddressVSize(receive)
  
    const scriptPkVsize = 10 + 9 //this.runes[0].scriptPubKey
    const mOutpVsize = 31
    const mInpVsize = 68
    const mOverhead = 10.5
    const cycleMinerFee =  Math.ceil(128.5 * feeRate) //257
    //  const endVsize = p2wpkhVsize.overhead + p2wpkhVsize.inpVsize + receiveVsize.outpVsize + scriptVsize
    const endVsize = (mOverhead + mInpVsize + inp.outpVsize + scriptPkVsize)
    const endMinerFee =  Math.ceil(endVsize * feeRate)
  
  
    let inpVsize = 0
    let inpVal = 0
    utxos.map((utxo: any) => {
        inpVsize += inp.inpVsize
        inpVal += utxo.value
    })
  
  
  
    let outpVsize = 0
    outpVsize += scriptPkVsize
    outpVsize += inp.outpVsize
  
    // let depth = 24
    // let oupth = Math.ceil(mints/depth)
   
  
    let cost = 0
    let outpVal = balance
    if(1<mints){
        const splitMints = mints-1
        const rune = groupRune(splitMints)
        rune.map((rune: any, i: number) => {
            const cycleTxMinterFee = (rune.mints - 1) * cycleMinerFee
            const minerFee = cycleTxMinterFee + endMinerFee
            const revealFee = minerFee + balance
            console.log("GetCost", i,'mints',rune.mints, { revealFee, minerFee, balance })
            cost += revealFee
            outpVal += revealFee
            outpVsize += mOutpVsize
        })
    }
  
    if(mints==1){
        service_fee=0
    }
    if (service_fee) {
        service_fee = Math.floor(cost*0.5) 
        if (10 < feeRate && feeRate < 31){
            let cost_fee = Math.floor(cost*0.4)
            if(cost_fee<service_fee){
                service_fee = cost_fee
            }
        }  else if (30 < feeRate && feeRate < 61){
            let cost_fee = Math.floor(cost*0.3)
            if(cost_fee<service_fee){
                service_fee = cost_fee
            }
        } else if(60 < feeRate && feeRate < 101){
            let cost_fee = Math.floor(cost*0.2)
            if(cost_fee<service_fee){
                service_fee = cost_fee
            }
        } else if(100<feeRate){
            let cost_fee = Math.floor(cost*0.1)
            if(cost_fee<service_fee){
                service_fee = cost_fee
            }
        }
  
        if(service_fee<330){
            service_fee = 0
        }
        
        outpVal += service_fee
        cost += service_fee
        outpVsize += mOutpVsize
    }
  
  
    let vsize = (inp.overhead + inpVsize + outpVsize)
    let minterFee = Math.ceil( vsize * feeRate)
    // 7178
  
    let change = inpVal - outpVal - minterFee
  
    if (change > balance) {
        outpVsize += inp.outpVsize
        vsize += inp.outpVsize
  
        const changeMinterFee = Math.ceil(inp.outpVsize * feeRate)
        minterFee += changeMinterFee
        change = change - changeMinterFee 
        outpVal += change
    } 
  
    //  let cost = 0
    const amount = cost + minterFee  //+ balance
  
    console.log("GetCost", {feeRate,vsize, inpVsize, outpVsize,minterFee, amount,  cost })
  
    return { amount, minterFee,cost,service_fee }
  }
  
  
  export function estimatePsbtVSize(psbt: any) {
  
    const input0 = psbt.data.inputs[0];
    // @ts-ignore
    if (input0.finalScriptSig || input0.finalScriptWitness) {
        console.log('unsign succ')
    }
  
    if (input0.tapInternalKey) {
        // const tweaked = keypair.tweak(
        //     bitcoinJs.crypto.taggedHash('TapTweak', internalPubkey),
        // );
        // psbt.signAllInputs(tweaked).finalizeAllInputs();
    } else {
        // psbt.signAllInputs(keypair).finalizeAllInputs();
    }
  
    // @ts-ignore 
    if (input0.finalScriptSig || input0.finalScriptWitness) {
        // debugger
        console.log('sign succ')
    }
    let inputVsize = 0
    for (let input of psbt.data.inputs) {
        inputVsize += (input.witnessUtxo?.script.length || 0) / 4
        // inputVsize +=input.nonWitnessUtxo?.length||0
        inputVsize += input.finalScriptSig?.length || 0
    }
  
    for (let input of psbt.txInputs) {
        inputVsize += (input.hash?.length || 0) // txhash
        inputVsize += 32 //sequence
        inputVsize += 2 //index
    }
  
    let outputVsize = 0
    for (let output of psbt.txOutputs) {
        // = 34字节 × 输出数量
        outputVsize += (output.script?.length || 0)
    }
  
    return outputVsize + inputVsize
  }
  