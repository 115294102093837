import { useQuery } from "@tanstack/react-query";
import { client } from "./api-client";

export const useGetUEFAList = () =>
  useQuery(["getUEFAList"], async () => {
    const response = await client(`uefa/list`);
    if (response?.msg === "success") {
      return response?.data;
    } else {
      return [];
    }
  });
