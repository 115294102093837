import {
  AbsoluteCenter,
  Box,
  Button,
  Flex,
  Image,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ControlsIco from "../assets/images/controls-ico.svg";
import CartIco from "../assets/images/cart-ico.svg";
import CaretIco from "../assets/images/caret-ico.svg";
import FilterIco from "../assets/images/filter.svg";
import { useSelector } from "react-redux";
import Sheet from "react-modal-sheet";
import SearchBlock from "./SearchBlock";

function DashboardHeader(props: any) {
  const { selectedItems, satStatus } = useSelector(
    (state: any) => state.general
  );

  const [showControls, setShowControls] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [tempActive, setTempActive] = useState("all");
  const [tempBlock, setTempBlock] = useState("");
  const [activeControls, setActiveControls] = useState(false);

  useEffect(() => {
    setTempActive(props.activeTab);
  }, [props.activeTab]);

  return (
    <>
      <Flex
        p={{ base: "8px", md: "20px 24px" }}
        align="center"
        justify="space-between"
        gap="20px"
      >
        <Flex gap="20px" w="100%" display={{ base: "none", xl: "flex" }}>
          {props?.tabItems.map((item: string, index: number) => (
            <Box
              key={index}
              onClick={() => {
                props.setRaritySats({
                  sat_minted: [],
                  sat_numbers: [],
                });
                if (item === "all") {
                  props?.setStart(~~Number(satStatus?.unclaimed_start));
                } else {
                  props.setStart(0);
                }
                props.setActiveTab(item);
              }}
              cursor="pointer"
            >
              <Text
                fontSize="16px"
                fontWeight={props.activeTab === item ? "600" : "500"}
                color={"#715693"}
                background={
                  props.activeTab === item
                    ? "linear-gradient(97deg, #CD23E9 44.31%, #AC00FD 90.84%)"
                    : ""
                }
                bgClip={props.activeTab === item ? "text" : ""}
                textTransform="capitalize"
              >
                {item}
              </Text>
              {props.activeTab === item && (
                <Box
                  mt="8px"
                  height="4px"
                  width="22px"
                  borderRadius="24px"
                  background="linear-gradient(97deg, #CD23E9 44.31%, #AC00FD 90.84%)"
                ></Box>
              )}
            </Box>
          ))}
        </Flex>
        <Flex
          align="center"
          justify="center"
          gap="8px"
          w={{ base: "100%", xl: "auto" }}
        >
          <SearchBlock />
          <Flex
            align="center"
            bg={{ base: "#000", xl: "#312F2F" }}
            borderRadius="8px"
            p="14px"
            pos="relative"
            display={{ base: "none", md: "flex" }}
          >
            <Box
              display="flex"
              gap="12px"
              cursor="pointer"
              onMouseOver={() => setShowControls(true)}
              onMouseOut={() => {
                setTimeout(() => {
                  setShowControls(false);
                }, 1000);
              }}
            >
              <Text color="#AFA9A9" fontSize="16px" fontWeight="400">
                Controls
              </Text>
              <Box width="24px" height="24px">
                <Image src={ControlsIco} alt="control" />
              </Box>
            </Box>
            {(showControls || activeControls) && (
              <Box
                pos="absolute"
                minW="256px"
                top="0"
                right="0"
                border="4px solid #A98ED6"
                zIndex="1000"
                marginTop="65px"
              >
                <Image
                  src={CaretIco}
                  alt="caret"
                  pos="absolute"
                  top="0"
                  right="0"
                  marginTop="-15px"
                  marginRight="15px"
                />
                <Text
                  fontSize="16px"
                  fontWeight="600"
                  color="#A98ED6"
                  p="10px"
                  bg="#533685"
                  textAlign="center"
                  borderBottom="4px solid #A98ED6"
                  cursor="pointer"
                  _hover={{
                    color: "#fff",
                  }}
                  onClick={() => {
                    props?.moveToScroll("first");
                  }}
                  onMouseOver={() => setActiveControls(true)}
                  onMouseOut={() => {
                    setTimeout(() => {
                      setActiveControls(false);
                    }, 1000);
                  }}
                >
                  Jump to first block
                </Text>
                <Text
                  fontSize="16px"
                  fontWeight="600"
                  color="#A98ED6"
                  p="10px"
                  bg="#533685"
                  textAlign="center"
                  cursor="pointer"
                  _hover={{
                    color: "#fff",
                  }}
                  onClick={() => props?.moveToScroll("last")}
                  onMouseOver={() => setActiveControls(true)}
                  onMouseOut={() => {
                    setTimeout(() => {
                      setActiveControls(false);
                    }, 1000);
                  }}
                >
                  Jump to last block
                </Text>
              </Box>
            )}
          </Flex>
          <Box
            pos="relative"
            width="56px"
            height="52px"
            bg={{ base: "#000", xl: "#312F2F" }}
            borderRadius="8px"
            cursor="pointer"
            onClick={() => props.setShowDrawer(true)}
            zIndex="1"
          >
            <AbsoluteCenter>
              <Image src={CartIco} alt="cart" />
            </AbsoluteCenter>
            <Box
              bg="#C91EEB"
              borderRadius="17px"
              position="absolute"
              top="0"
              right="0"
              marginRight="-10px"
              marginTop="-5px"
              p="2px 6px"
            >
              <Text fontSize="11px" fontWeight="600" color="#fff">
                {selectedItems?.length}
              </Text>
            </Box>
          </Box>
          <Box
            pos="relative"
            width="56px"
            height="52px"
            bg="#000"
            borderRadius="8px"
            cursor="pointer"
            display={{ base: "block", xl: "none" }}
            onClick={() => setIsFilterOpen(true)}
          >
            <AbsoluteCenter>
              <Image src={FilterIco} alt="cart" />
            </AbsoluteCenter>
          </Box>
        </Flex>
      </Flex>
      <Sheet
        isOpen={isFilterOpen}
        onClose={() => setIsFilterOpen(false)}
        detent="content-height"
      >
        <Sheet.Container
          style={{
            background: "#180530",
            borderRadius: "16px 16px 0px 0px",
          }}
        >
          <Sheet.Header />
          <Sheet.Content style={{ padding: "0px 24px 24px 24px" }}>
            <Text
              color="#fff"
              fontSize="20px"
              fontWeight="600"
              lineHeight="32px"
            >
              Filter
            </Text>
            <Text
              mt="12px"
              color="#fff"
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="500"
              lineHeight="32px"
            >
              Select Asset
            </Text>
            <Flex mt="8px" gap="6px" flexWrap="wrap" bg="transparent">
              {props?.tabItems?.map((tab: string, idx: number) => (
                <Box
                  key={idx}
                  p="2px 32px"
                  border="1px solid #CD23E9"
                  borderRadius="30px"
                  bg={
                    tempActive === tab
                      ? "linear-gradient(97deg, #CD23E9 44.31%, #AC00FD 90.84%)"
                      : "transparent"
                  }
                  cursor="pointer"
                  onClick={() => {
                    setTempActive(tab);
                  }}
                >
                  <Text
                    color={tempActive === tab ? "#fff" : "#9B88BC"}
                    fontFamily="Inter"
                    fontSize="14px"
                    fontWeight="400"
                    lineHeight="32px"
                    textTransform="capitalize"
                  >
                    {tab}
                  </Text>
                </Box>
              ))}
            </Flex>
            <Button
              mt="28px"
              color="#fff"
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="500"
              lineHeight="16px"
              height="48px"
              padding="16px 0px"
              borderRadius="48px"
              maxW="450px"
              width="100%"
              marginLeft="auto"
              marginRight="auto"
              bg="#8259C8"
              _hover={{
                background: "transparent",
                border: "2px solid #8259c8",
              }}
              onClick={() => {
                props.setRaritySats({
                  sat_minted: [],
                  sat_numbers: [],
                });
                if (tempActive === "all") {
                  props?.setStart(~~Number(satStatus?.unclaimed_start));
                } else {
                  props.setStart(0);
                }
                props.setActiveTab(tempActive);
                setIsFilterOpen(false);
              }}
            >
              Apply
            </Button>
            <Button
              mt="8px"
              color="#D0CECE"
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="500"
              lineHeight="16px"
              height="48px"
              padding="16px 0px"
              borderRadius="48px"
              maxW="450px"
              width="100%"
              marginLeft="auto"
              marginRight="auto"
              bg="transparent"
              _hover={{
                background: "transparent",
                border: "2px solid #8259c8",
              }}
              onClick={() => setIsFilterOpen(false)}
            >
              Cancel
            </Button>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop />
      </Sheet>
    </>
  );
}

export default DashboardHeader;
