import {
  Button,
  Container,
  Flex,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import SearchBlock from "./SearchBlock";
import GradientColor from "./GradientColor";
import { useSelector } from "react-redux";
import { useGetHolderRanks } from "../utils/sats.api";

function HolderPage() {
  const toShow = 10;
  const [start, setStart] = useState(0);
  const { satStatus } = useSelector((state: any) => state.general);
  const { data: holderRanks, isLoading, refetch } = useGetHolderRanks(start);
  const totalPages = Math.ceil(holderRanks?.data?.total / toShow);
  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start]);
  return (
    <Container
      maxW="1720px"
      background="rgba(255, 255, 255, 0.15)"
      p="20px"
      mb="25px"
      pos="relative"
      borderRadius="8px"
      mt={{ base: "25px", xl: "30px" }}
    >
      <Flex justify="end">
        <SearchBlock />
      </Flex>

      <Flex
        justify="center"
        flexDirection={{ base: "column", sm: "row" }}
        mt="20px"
        ml={{ base: "15%", sm: "0" }}
      >
        <Flex align="center" gap="16px" mr="52px">
          <GradientColor title="Supply" fontSize="18px" fontWeight="600" />
          <Text
            fontWeight="500"
            fontSize="18px"
            fontStyle="normal"
            color="#fff"
          >
            {satStatus?.total_supply || 0}
          </Text>
        </Flex>
        <Flex align="center" gap="16px">
          <GradientColor title="Holders" fontSize="18px" fontWeight="600" />
          <Text
            fontWeight="500"
            fontSize="18px"
            fontStyle="normal"
            color="#fff"
          >
            {satStatus?.holder_supply || 0}
          </Text>
        </Flex>
      </Flex>

      <TableContainer maxW="1150px" marginLeft="auto" marginRight="auto">
        {isLoading ? (
          <Flex justify="center" mt="20px">
            <Spinner size="md" color="#fff" />
          </Flex>
        ) : (
          <Table
            mt="20px"
            // bg="#000000"
            borderRadius="10px"
            color="#fff"
          >
            <Thead>
              <Th>Rank</Th>
              <Th>Address</Th>
              {/* <Th>Percentage</Th> */}
              <Th>Items</Th>
            </Thead>
            <Tbody>
              {holderRanks?.data?.ranks?.map((holder: any, idx: number) => (
                <Tr key={idx}>
                  <Td borderBottom="0px">{holder?.rank}</Td>
                  <Td borderBottom="0px" cursor="pointer">
                    {holder?.address}
                  </Td>
                  {/* <Td borderBottom="0px" display="flex" flexDir="column">
                    <Text>{holder?.percentage}%</Text>
                    <Progress value={holder?.percentage} height="5px" />
                  </Td> */}
                  <Td borderBottom="0px">
                    {Number(holder?.items)?.toLocaleString()}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
        {holderRanks?.data?.ranks?.length === 0 && (
          <Text textAlign="center" color="#fff">
            No Records to show
          </Text>
        )}
      </TableContainer>
      <Flex justify="center" gap="5px" mt="20px">
        {start !== 0 && (
          <Button
            color="#fff"
            bg="#000000"
            _hover={{
              bg: "#000000",
            }}
            isDisabled={start === 0 || isLoading}
            onClick={() => setStart(start - 10)}
          >
            Prev
          </Button>
        )}
        {start + 1 < totalPages * 10 && (
          <Button
            color="#fff"
            bg="#000000"
            _hover={{
              bg: "#000000",
            }}
            isDisabled={isLoading || holderRanks?.data?.ranks?.length === 0}
            onClick={() => setStart(start + 10)}
          >
            Next
          </Button>
        )}
      </Flex>
    </Container>
  );
}

export default HolderPage;
