import {
  Box,
  Button,
  Container,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import Logo from "../assets/images/RuneBox.svg";
import xIco from "../assets/images/x-ico.svg";
import discordIco from "../assets/images/discord-ico.svg";
import githubIco from "../assets/images/gitread-ico.svg";
import mosacIco from "../assets/images/mosac-ico.svg";
import meIco from "../assets/images/me-ico.svg";
import mythic from "../assets/images/mythic.svg";
import legendary from "../assets/images/legendary.svg";
import epic from "../assets/images/epic.svg";
import rare from "../assets/images/rare.svg";
import uncommon from "../assets/images/uncommon.svg";
import HamburgerIco from "../assets/images/humburger.svg";
import InfoCard from "./InfoCard";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CancelIco from "../assets/images/cancel-ico.svg";
import ArrowDown from "../assets/images/arrowDown.svg";
import OKXIco from "../assets/images/okx.svg";
import UnisatIco from "../assets/images/unisat.svg";
import { toast } from "react-toastify";
import {
  useClaimBonus,
  useGetNetworkFee,
  useGetStatus,
} from "../utils/sats.api";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveAccount,
  setSatStatus,
  setStatusLoading,
} from "../store/slices/generalSlice";
import Sheet from "react-modal-sheet";
import PageTab from "./PageTab";
import { IconCoinBitcoinFilled, IconGasStation } from "@tabler/icons-react";
declare global {
  interface Window {
    unisat: any;
    okxwallet: any;
  }
}

function Header({ isOpen, onOpen, onClose, isBonus, onBonusClose }: any) {
  const Items = [
    {
      name: "Mythic",
      total: "1",
      acquired: "1",
    },
    {
      name: "Legendary",
      total: "5",
      acquired: "0",
    },
    {
      name: "Epic",
      total: "32",
      acquired: "27",
    },
    {
      name: "Rare",
      total: "3456",
      acquired: "342",
    },
    {
      name: "Uncommon",
      total: "6929999",
      acquired: "808262",
    },
  ];

  const { data: satsStatus, isLoading } = useGetStatus();
  const { data: networkFee } = useGetNetworkFee();
  const claimBonus = useClaimBonus();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [showInfo, setShowInfo] = useState(false);
  const [isInfoSheet, setIsInfoSheet] = useState(false);
  const [isSocialMedia, setIsSocialMedia] = useState(false);
  const [isMenu, setIsMenu] = useState(false);
  const [isFollowed, setIsFollowed] = useState(false);

  const { activeAccount, satStatus } = useSelector(
    (state: any) => state.general
  );
  const checkSupply = (name: any) => {
    const lowerName = name?.toLowerCase() + "_supply";
    return satStatus?.[lowerName];
  };

  const connectUnisat = async () => {
    if (typeof window?.unisat !== "undefined") {
      try {
        let accounts = await window.unisat.requestAccounts();
        let publicKey = await window.unisat.getPublicKey();
        dispatch(
          setActiveAccount({
            address: accounts?.[0],
            wallet: "Unisat",
            pubKey: publicKey,
          })
        );
        onClose();
      } catch (e: any) {
        toast.warn(e?.message || "Something went wrong, please try again!");
      }
    } else {
      toast.warn(`Unisat wallet isn't installed. Please try again!`);
    }
  };

  const checkWalletConnection = async () => {
    try {
      let res = await window.unisat.getAccounts();
      let accounts = await window.okxwallet.bitcoin.connect();
      if (activeAccount?.wallet === "OKX") {
        dispatch(
          setActiveAccount({
            address: accounts?.address,
            pubKey: accounts?.publicKey,
            wallet: "OKX",
          })
        );
      } else {
        dispatch(
          setActiveAccount({
            address: res?.[0], 
            wallet: "Unisat",
          })
        );
      }
    } catch (e) {
      // console.log(e);
    }
  };

  const connectOKX = async () => {
    const ua = navigator.userAgent;
    const isIOS = /iphone|ipad|ipod|ios/i.test(ua);
    const isAndroid = /android|XiaoMi|MiuiBrowser/i.test(ua);
    const isMobile = isIOS || isAndroid;
    const isOKApp = /OKApp/i.test(ua);

    if (isMobile && !isOKApp) {
      const encodedUrl =
        "https://www.okx.com/download?deeplink=" +
        encodeURIComponent(
          "okx://wallet/dapp/url?dappUrl=" +
          encodeURIComponent(window?.location?.href)
        );
      window.location.href = encodedUrl;
    } else if (window?.okxwallet) {
      try {
        let accounts = await window.okxwallet.bitcoin.connect();
        dispatch(
          setActiveAccount({
            address: accounts?.address,
            pubKey: accounts?.publicKey,
            wallet: "OKX",
          })
        );
        onClose();
      } catch (e: any) {
        toast.warn(e?.message || "Something went wrong, please try again!");
      }
    } else {
      toast.warn(`Please try again!`);
    }
  };

  const checkWallet = (name: string) => {
    if (activeAccount?.wallet === name && activeAccount?.address) {
      return true;
    } else {
      return false;
    }
  };

  const disconnectWallet = () => {
    dispatch(
      setActiveAccount({
        address: "",
        pubKey: "",
        wallet: "",
      })
    );
  };

  const refactorWalletAddress = (address: any) => {
    const firstThree = address.slice(0, 4);
    const lastThree = address.slice(-4);
    return firstThree + "***" + lastThree;
  };

  useEffect(() => {
    dispatch(setSatStatus(satsStatus?.data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [satsStatus?.data]);
  useEffect(() => {
    dispatch(setStatusLoading(isLoading));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    checkWalletConnection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box pt="20px">
      <Container maxW="1555px">
        <Flex justify="space-between" align="center" gap="20px">
          <Flex
            gap="22px"
            align="center"
            justify={{ base: "space-between", xl: "start" }}
            w={{ base: "100%", xl: "auto" }}
          >
            <Box
              borderRadius="6px"
              border="1px solid rgba(255, 255, 255, 0.15)"
              height="36px"
              width="42px"
              display={{ base: "flex", xl: "none" }} // Mobile View
              alignItems="center"
              justifyContent="center"
              cursor="pointer"
              onClick={() => setIsSocialMedia(true)}
            >
              <Image src={xIco} alt="x" />
              <Image src={ArrowDown} alt="down" />
            </Box>
            <Box
              cursor="pointer"
              mr={{ xl: "22px" }}
              onClick={() => navigate("/")}
            >
              <Image src={Logo} alt="logo" height="32px" />
            </Box>
            <Box
              borderRadius="6px"
              border="1px solid rgba(255, 255, 255, 0.15)"
              height="36px"
              width="42px"
              display={{ base: "flex", xl: "none" }} // Mobile View
              alignItems="center"
              justifyContent="center"
              cursor="pointer"
              onClick={() => setIsMenu(true)}
            >
              <Image src={HamburgerIco} alt="menu" />
            </Box>

            <Flex
              padding="10px 16px"
              background="#262626"
              borderRadius="60px"
              gap="10px"
              mr="81px"
              display={{ base: "none", xl: "flex" }} // Desktop View
            >
              <Image
                src={xIco}
                alt="x"
                cursor="pointer"
                onClick={() =>
                  window?.open("https://twitter.com/runebox_app", "_blank")
                }
              />
              <Tooltip hasArrow label="Coming Soon" bg="#8B69C5" color="#fff">
                <Image src={discordIco} alt="discord" />
              </Tooltip>
              <Image
                src={githubIco}
                alt="docs"
                cursor="pointer"
                onClick={() =>
                  window?.open("https://docs.runebox.app/runebox", "_blank")
                }
              />

              <Image
                src={mosacIco}
                alt="okx"
                cursor="pointer"
                onClick={() =>
                  window?.open("https://www.okx.com/zh-hans/web3/marketplace/runes/token/SUPER%E2%80%A2MARIO%E2%80%A2COIN/840000:134", "_blank")
                }
              />

              <Image 
                src={meIco} 
                alt="magiceden"
                cursor="pointer"
                onClick={() =>
                  window?.open("https://magiceden.io/runes/SUPER%E2%80%A2MARIO%E2%80%A2COIN", "_blank")
                }
              />

            </Flex>
          </Flex>
          <Flex display={{ base: "none", xl: "flex" }} gap="20px">
            <PageTab title="Launchpad" path="/" />
            <PageTab title="Map" path="/map" />
          </Flex>
          <Flex
            align="center"
            gap="25px"
            display={{ base: "none", xl: "flex" }}
          >
            {location?.pathname === "/" ? (
              <Flex justify="center" gap="8px">
                <Flex
                  justify="center"
                  gap="4px"
                  padding="10px 16px"
                  background="#262626"
                  borderRadius="60px"
                  align="center"
                >
                  <IconCoinBitcoinFilled
                    size="16px"
                    style={{
                      fill: "#F7931A",
                      color: "#F7931A",
                    }}
                  />
                  <Text color="#fff" fontSize="12px" fontWeight="600">
                    ${Number(satStatus?.btc_price || 0)?.toLocaleString()}
                  </Text>
                </Flex>
                <Flex
                  justify="center"
                  gap="4px"
                  padding="10px 16px"
                  background="#262626"
                  borderRadius="60px"
                  align="center"
                >
                  <IconGasStation size="16px" color="#fff" />
                  <Text color="#fff" fontSize="12px" fontWeight="600">
                    {Number(
                      networkFee?.data?.halfHourFee || 0
                    )?.toLocaleString()}{" "}
                    sats/vB
                  </Text>
                </Flex>
              </Flex>
            ) : (
              <Flex align="center" gap="4px">
                <Image
                  src={mythic}
                  alt="mythic"
                  cursor="pointer"
                  onMouseOver={() => {
                    setShowInfo(true);
                  }}
                  onMouseOut={() => {
                    setShowInfo(false);
                  }}
                />
                <Image
                  src={legendary}
                  alt="legendary"
                  cursor="pointer"
                  onMouseOver={() => {
                    setShowInfo(true);
                  }}
                  onMouseOut={() => {
                    setShowInfo(false);
                  }}
                />
                <Image
                  src={epic}
                  alt="epic"
                  cursor="pointer"
                  onMouseOver={() => {
                    setShowInfo(true);
                  }}
                  onMouseOut={() => {
                    setShowInfo(false);
                  }}
                />
                <Image
                  src={rare}
                  alt="rare"
                  cursor="pointer"
                  onMouseOver={() => {
                    setShowInfo(true);
                  }}
                  onMouseOut={() => {
                    setShowInfo(false);
                  }}
                />
                <Image
                  src={uncommon}
                  alt="uncommon"
                  cursor="pointer"
                  onMouseOver={() => {
                    setShowInfo(true);
                  }}
                  onMouseOut={() => {
                    setShowInfo(false);
                  }}
                />
              </Flex>
            )}
            <Button
              bgGradient="linear-gradient(97deg, #CD23E9 44.31%, #AC00FD 90.84%)"
              color="#fff"
              fontSize="16px"
              fontWeight="600"
              borderRadius="66px"
              minH={{ base: "24px", md: "54px" }}
              minW={{ base: "auto", md: "126px" }}
              _hover={{
                bgGradient: "none",
                bg: "transparent",
                border: "2px solid #CD23E9",
              }}
              onClick={onOpen}
            >
              {activeAccount?.address
                ? refactorWalletAddress(activeAccount?.address)
                : "Connect"}
            </Button>
          </Flex>
        </Flex>
        <Flex
          align="center"
          justify="space-between"
          gap="4px"
          display={{ base: "flex", xl: "none" }}
          mt="25px"
        >
          {location?.pathname === "/" ? (
            <Flex justify="center" gap="8px">
              <Flex
                justify="center"
                gap="4px"
                padding={{ base: "4px 6px", md: "10px 16px" }}
                background="#262626"
                borderRadius="60px"
                align="center"
              >
                <IconCoinBitcoinFilled
                  size="16px"
                  style={{
                    fill: "#F7931A",
                    color: "#F7931A",
                  }}
                />
                <Text color="#fff" fontSize="12px" fontWeight="600">
                  ${Number(satStatus?.btc_price || 0)?.toLocaleString()}
                </Text>
              </Flex>
              <Flex
                justify="center"
                gap="4px"
                padding={{ base: "4px 6px", md: "10px 16px" }}
                background="#262626"
                borderRadius="60px"
                align="center"
              >
                <IconGasStation size="16px" color="#fff" />
                <Text color="#fff" fontSize="12px" fontWeight="600">
                  {Number(networkFee?.data?.halfHourFee || 0)?.toLocaleString()}{" "}
                  sats/vB
                </Text>
              </Flex>
            </Flex>
          ) : (
            <Flex gap="4px">
              <Image
                src={mythic}
                alt="mythic"
                height={{ base: "32px", md: "52px" }}
                cursor="pointer"
                onClick={() => setIsInfoSheet(true)}
              />
              <Image
                src={legendary}
                alt="legendary"
                height={{ base: "32px", md: "52px" }}
                cursor="pointer"
                onClick={() => setIsInfoSheet(true)}
              />
              <Image
                src={epic}
                alt="epic"
                height={{ base: "32px", md: "52px" }}
                cursor="pointer"
                onClick={() => setIsInfoSheet(true)}
              />
              <Image
                src={rare}
                alt="rare"
                height={{ base: "32px", md: "52px" }}
                cursor="pointer"
                onClick={() => setIsInfoSheet(true)}
              />
              <Image
                src={uncommon}
                alt="uncommon"
                height={{ base: "32px", md: "52px" }}
                cursor="pointer"
                onClick={() => setIsInfoSheet(true)}
              />
            </Flex>
          )}
          <Button
            bgGradient="linear-gradient(97deg, #CD23E9 44.31%, #AC00FD 90.84%)"
            color="#fff"
            fontSize="16px"
            fontWeight="600"
            borderRadius="66px"
            minH={{ base: "24px", md: "54px" }}
            minW={{ base: "auto", md: "126px" }}
            _hover={{
              bgGradient: "none",
              bg: "transparent",
              border: "2px solid #CD23E9",
            }}
            onClick={onOpen}
          >
            {activeAccount?.address
              ? refactorWalletAddress(activeAccount?.address)
              : "Connect"}
          </Button>
        </Flex>
      </Container>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent bg="#1C1022" minW={{ base: "320px", md: "524px" }}>
          <ModalHeader
            fontSize="24px"
            fontWeight="600"
            color="#fff"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            Connect Wallet
            <Box cursor="pointer" onClick={onClose}>
              <Image src={CancelIco} alt="cancel" />
            </Box>
          </ModalHeader>
          <ModalBody mb="20px" fontFamily="Inter">
            <Text color="#EDECEC" fontSize="20px" fontWeight="400">
              Please select the connection method
            </Text>
            <Flex
              align="center"
              gap="20px"
              bg="#332B37"
              borderRadius="7px"
              p="15px"
              mt="24px"
              cursor="pointer"
              onClick={() => {
                connectOKX();
              }}
            >
              <Image src={OKXIco} alt="OKX" />
              <Text color="#fff" fontSize="20px" fontWeight="400">
                OKX Wallet{" "}
                {checkWallet("OKX") && (
                  <small style={{ color: "#CD23E9" }}>(Connected)</small>
                )}{" "}
              </Text>
            </Flex>
            <Flex
              align="center"
              gap="20px"
              bg="#332B37"
              borderRadius="7px"
              p="15px"
              mt="16px"
              cursor="pointer"
              onClick={() => {
                connectUnisat();
              }}
            >
              <Image src={UnisatIco} alt="Unisat" />
              <Text color="#fff" fontSize="20px" fontWeight="400">
                Unisat Wallet{" "}
                {checkWallet("Unisat") && (
                  <small style={{ color: "#CD23E9" }}>(Connected)</small>
                )}
              </Text>
            </Flex>
            {activeAccount?.address && (
              <Flex
                borderRadius="6px"
                bg="transparent"
                border={"1px solid #CD23E9"}
                h="52px"
                _hover={{
                  background: "transparent",
                }}
                align="center"
                gap="20px"
                mt="16px"
                cursor="pointer"
                justify="center"
                onClick={() => {
                  disconnectWallet();
                  onClose();
                }}
              >
                <Text color="#fff" fontSize="20px" fontWeight="400">
                  Disconnect Wallet{" "}
                </Text>
              </Flex>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
      <Sheet
        isOpen={isInfoSheet}
        onClose={() => setIsInfoSheet(false)}
        detent="content-height"
      >
        <Sheet.Container
          style={{
            background: "#180530",
            borderRadius: "16px 16px 0px 0px",
          }}
        >
          <Sheet.Header />
          <Sheet.Content style={{ padding: "0px 24px 24px 24px" }}>
            <Text
              color="#fff"
              fontSize="20px"
              fontWeight="600"
              lineHeight="32px"
            >
              Asset Stats
            </Text>

            {Items?.map((item: any, idx: number) => (
              <>
                <Flex
                  align="center"
                  justify="space-between"
                  gap="10px"
                  mt="10px"
                  key={idx}
                >
                  <Text
                    color="#A98ED6"
                    fontSize="18px"
                    fontWeight="500"
                    width="100%"
                    maxW="130px"
                  >
                    {item?.name}
                  </Text>
                  <Flex w="100%" maxW="220px" justify="end">
                    <Text color="#A77EDA" fontSize="16px" fontWeight="500">
                      {checkSupply(item?.name) || 0}
                    </Text>
                    <Text color="#fff" fontSize="16px" fontWeight="500">
                      /
                    </Text>
                    <Text color="#fff" fontSize="16px" fontWeight="500">
                      {item?.total}
                    </Text>
                  </Flex>
                </Flex>
                <Box
                  mt="5px"
                  width="100%"
                  height="35px"
                  border="1px solid #CD23E9"
                  borderTopEndRadius="12px"
                  p="2px"
                >
                  <Box
                    bg="linear-gradient(97deg, #CD23E9 44.31%, #AC00FD 90.84%)"
                    width={
                      (Number(checkSupply(item?.name) || 0) /
                        Number(item?.total)) *
                      100 +
                      "%"
                    }
                    height="100%"
                    borderTopEndRadius="10px"
                  ></Box>
                </Box>
              </>
            ))}
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop />
      </Sheet>
      <Sheet
        isOpen={isSocialMedia}
        onClose={() => setIsSocialMedia(false)}
        detent="content-height"
      >
        <Sheet.Container
          style={{
            background: "#180530",
            borderRadius: "16px 16px 0px 0px",
          }}
        >
          <Sheet.Header />
          <Sheet.Content style={{ padding: "0px 24px 24px 24px" }}>
            <Text
              color="#fff"
              fontSize="20px"
              fontWeight="600"
              lineHeight="32px"
            >
              Social Links
            </Text>
            <Flex mt="20px" justify="center" gap="5px" flex="wrap">
              <Flex
                justify="center"
                align="center"
                bg="rgba(44, 15, 81, 0.29)"
                borderRadius="4px"
                width="66px"
                height="49px"
                cursor="pointer"
                onClick={() =>
                  window?.open("https://twitter.com/runebox_app", "_blank")
                }
              >
                <Image src={xIco} alt="X" />
              </Flex>
              <Flex
                justify="center"
                align="center"
                bg="rgba(44, 15, 81, 0.29)"
                borderRadius="4px"
                width="66px"
                height="49px"
                cursor="pointer"
              >
                <Image src={discordIco} alt="discord" />
              </Flex>
              <Flex
                justify="center"
                align="center"
                bg="rgba(44, 15, 81, 0.29)"
                borderRadius="4px"
                width="66px"
                height="49px"
                cursor="pointer"
                onClick={() =>
                  window?.open("https://docs.runebox.app/runebox", "_blank")
                }
              >
                <Image src={githubIco} alt="gitRead" />
              </Flex>
            </Flex>
            <Flex justify="center" mt="5px" gap="5px" align="center">
              <Flex
                justify="center"
                align="center"
                bg="rgba(44, 15, 81, 0.29)"
                borderRadius="4px"
                minWidth="103px"
                height="49px"
                cursor="pointer"
                onClick={() =>
                  window?.open("https://www.okx.com/zh-hans/web3/marketplace/runes/token/SUPER%E2%80%A2MARIO%E2%80%A2COIN/840000:134", "_blank")
                }
              >
                <Image src={mosacIco} alt="m" />
              </Flex>
              <Flex
                justify="center"
                align="center"
                bg="rgba(44, 15, 81, 0.29)"
                borderRadius="4px"
                minWidth="103px"
                height="49px"
                cursor="pointer"
                onClick={() =>
                  window?.open("https://magiceden.io/runes/SUPER%E2%80%A2MARIO%E2%80%A2COIN", "_blank")
                }
              >
                <Image src={meIco} alt="m" />
              </Flex>
            </Flex>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop />
      </Sheet>
      <Sheet
        isOpen={isMenu}
        onClose={() => setIsMenu(false)}
        detent="content-height"
      >
        <Sheet.Container
          style={{
            background: "#180530",
            borderRadius: "16px 16px 0px 0px",
          }}
        >
          <Sheet.Header />
          <Sheet.Content style={{ padding: "0px 24px 24px 24px" }}>
            <Text
              color="#fff"
              fontSize="20px"
              fontWeight="600"
              lineHeight="32px"
            >
              RuneBox
            </Text>
            <Flex
              gap="10px"
              mt="20px"
              justify="center"
              align="center"
              flexDir="column"
            >
              <PageTab title="Launchpad" path="/" />
              <PageTab title="Map" path="/map" />
              {/* <PageTab title="Holders" path="/holders" />
              <PageTab title="My Mints" path="/my-mints" /> */}
            </Flex>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop />
      </Sheet>
      <Modal isOpen={isBonus} onClose={() => { }} isCentered>
        <ModalOverlay />
        <ModalContent bg="#1C1022" minW={{ base: "320px", md: "524px" }}>
          <ModalHeader
            fontSize="24px"
            fontWeight="600"
            color="#fff"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            Earn Bonus
            <Box
              cursor="pointer"
              onClick={() => {
                onBonusClose();
                localStorage.setItem("isClaimed", "true");
              }}
            >
              <Image src={CancelIco} alt="cancel" />
            </Box>
          </ModalHeader>
          <ModalBody mb="20px" fontFamily="Inter">
            <Text color="#EDECEC" fontSize="16px" fontWeight="400">
              Follow the official X account, connect your wallet, and earn an
              additional 10% bonus points.{" "}
            </Text>
            <Flex gap="10px" mt="20px">
              <Button
                w="100%"
                h="40px"
                color="#fff"
                bg={
                  isFollowed
                    ? "linear-gradient(97deg, #CD23E9 44.31%, #AC00FD 90.84%)"
                    : "#533685"
                }
                autoFocus={false}
                boxShadow="0px 4px 34px 0px rgba(134, 77, 161, 0.01)"
                _hover={{
                  background: isFollowed
                    ? "linear-gradient(97deg, #CD23E9 44.31%, #AC00FD 90.84%)"
                    : "#533685",
                  boxShadow: "none",
                }}
                onClick={() => {
                  window?.open("https://twitter.com/runebox_app", "_blank");
                  setIsFollowed(true);
                }}
              >
                1: Follow
              </Button>
              <Button
                w="100%"
                h="40px"
                color="#fff"
                bg={
                  activeAccount?.address
                    ? "linear-gradient(97deg, #CD23E9 44.31%, #AC00FD 90.84%)"
                    : "#533685"
                }
                boxShadow="0px 4px 34px 0px rgba(134, 77, 161, 0.01)"
                _hover={{
                  background: activeAccount?.address
                    ? "linear-gradient(97deg, #CD23E9 44.31%, #AC00FD 90.84%)"
                    : "#533685",
                  boxShadow: "none",
                }}
                onClick={onOpen}
              >
                2:{" "}
                {activeAccount?.address
                  ? activeAccount?.address?.slice(0, 4) +
                  "****" +
                  activeAccount?.address?.slice(-4)
                  : "Connect"}
              </Button>
            </Flex>
            <Button
              mt="20px"
              w="100%"
              h="40px"
              color="#fff"
              bg={
                isFollowed && activeAccount?.address
                  ? "linear-gradient(97deg, #CD23E9 44.31%, #AC00FD 90.84%)"
                  : "#533685"
              }
              boxShadow="0px 4px 34px 0px rgba(134, 77, 161, 0.01)"
              border="2px solid #533685"
              _hover={{
                background:
                  isFollowed && activeAccount?.address
                    ? "linear-gradient(97deg, #CD23E9 44.31%, #AC00FD 90.84%)"
                    : "#533685",
                border: "2px solid #CD23E9",
                boxShadow: "none",
              }}
              isDisabled={claimBonus?.isLoading}
              isLoading={claimBonus?.isLoading}
              onClick={() => {
                if (activeAccount?.address && isFollowed) {
                  claimBonus
                    ?.mutateAsync({
                      address: activeAccount?.address,
                      payload: {
                        twitter: "runebox_app",
                      },
                    })
                    .then(() => {
                      toast.success("Bonus Enabled");
                      localStorage.setItem("isClaimed", "true");
                      onBonusClose();
                    })
                    .catch((err: any) => {
                      toast.warn(err?.message || "Something went wrong");
                    });
                } else {
                  toast.warn(
                    "Please perform Step1 & Step2 to activiate the bonus!"
                  );
                }
              }}
            >
              Activate Bonus
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>

      {showInfo && <InfoCard Items={Items} />}
    </Box>
  );
}

export default Header;
