import {
  Box,
  Button,
  Flex,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import { IconArrowsUpDown, IconChevronLeft } from "@tabler/icons-react";
import { IconSortAscending, IconSortDescending } from "@tabler/icons-react";
import { IconChevronRight } from "@tabler/icons-react";
import React, { useState } from "react";
import { useGetRunesList } from "../utils/runes.api";
import { useSearchParams } from "react-router-dom";

function LaunchpadComponent({
  setSearchByName,
  setRuneCart,
  runeCart,
  searchByName,
}: any) {
  const [searchParams, setSearchParams] = useSearchParams("");
  const [isHovered, setIsHovered] = useState(-1);

  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [sortFilter, setSortFilter] = useState({
    name: "",
    order: "",
  });

  const { data: runes, isLoading: runesLoading } = useGetRunesList();

  const filteredRunes = () => {
    if (searchByName && !runeCart) {
      return runes?.filter((rune: any) =>
        rune?.spacedRune?.toLowerCase().includes(searchByName?.toLowerCase())
      );
    } else {
      return runes;
    }
  };

  const sortedRunes = () => {
    const runes = filteredRunes();
    if (sortFilter.name === "rune") {
      return runes?.sort((a: any, b: any) =>
        sortFilter.order === "asc"
          ? a?.spacedRune?.localeCompare(b?.spacedRune)
          : b?.spacedRune?.localeCompare(a?.spacedRune)
      );
    }
    if (sortFilter.name === "progress") {
      return runes?.sort((a: any, b: any) =>
        sortFilter.order === "asc"
          ? a.progress - b.progress
          : b.progress - a.progress
      );
    }
    if (sortFilter.name === "mints") {
      return runes?.sort((a: any, b: any) =>
        sortFilter.order === "asc" ? a.mints - b.mints : b.mints - a.mints
      );
    }
    if (sortFilter.name === "holders") {
      return runes?.sort((a: any, b: any) =>
        sortFilter.order === "asc"
          ? a.holders - b.holders
          : b.holders - a.holders
      );
    }
    if (sortFilter.name === "premine") {
      return runes?.sort((a: any, b: any) =>
        sortFilter.order === "asc"
          ? a.preminePercentage - b.preminePercentage
          : b.preminePercentage - a.preminePercentage
      );
    }
    if (sortFilter.name === "number") {
      return runes?.sort((a: any, b: any) =>
        sortFilter.order === "asc" ? a.number - b.number : b.number - a.number
      );
    }
    return runes;
  };

  return (
    <Flex flexDir="column" gap="16px" mb="20px">
      {runesLoading ? (
        <Flex justify="center" my="20px">
          <Spinner size="md" color="#fff" />
        </Flex>
      ) : runes?.length > 0 ? (
        <TableContainer overflow="auto">
          <Table>
            {/* border="1.5px solid #3A3A3A" */}
            <Thead bg="#101010">
              <Th border="0" py="24px" ps="32px">
                <Flex display="flex" align="center" gap="4px">
                  <Text fontSize="10px" fontWeight="600" color="#B3B3B3">
                    RUNE
                  </Text>
                  {sortFilter?.name === "rune" &&
                  sortFilter?.order === "asc" ? (
                    <IconSortAscending
                      color="#B3B3B3"
                      size="14px"
                      onClick={() => {
                        setSortFilter({
                          name: "rune",
                          order: "desc",
                        });
                      }}
                    />
                  ) : sortFilter?.name === "rune" &&
                    sortFilter?.order === "desc" ? (
                    <IconSortDescending
                      color="#B3B3B3"
                      size="14px"
                      onClick={() => {
                        setSortFilter({
                          name: "rune",
                          order: "asc",
                        });
                      }}
                    />
                  ) : (
                    <IconArrowsUpDown
                      color="#B3B3B3"
                      size="14px"
                      onClick={() => {
                        setSortFilter({
                          name: "rune",
                          order: "asc",
                        });
                      }}
                    />
                  )}
                </Flex>
              </Th>
              <Th border="0" py="10px">
                <Flex display="flex" align="center" gap="4px">
                  <Text fontSize="10px" fontWeight="600" color="#B3B3B3">
                    Progress
                  </Text>
                  {sortFilter?.name === "progress" &&
                  sortFilter?.order === "asc" ? (
                    <IconSortAscending
                      color="#B3B3B3"
                      size="14px"
                      onClick={() => {
                        setSortFilter({
                          name: "progress",
                          order: "desc",
                        });
                      }}
                    />
                  ) : sortFilter?.name === "progress" &&
                    sortFilter?.order === "desc" ? (
                    <IconSortDescending
                      color="#B3B3B3"
                      size="14px"
                      onClick={() => {
                        setSortFilter({
                          name: "progress",
                          order: "asc",
                        });
                      }}
                    />
                  ) : (
                    <IconArrowsUpDown
                      color="#B3B3B3"
                      size="14px"
                      onClick={() => {
                        setSortFilter({
                          name: "progress",
                          order: "asc",
                        });
                      }}
                    />
                  )}
                </Flex>
              </Th>
              <Th border="0" py="10px">
                <Flex display="flex" align="center" gap="4px">
                  <Text fontSize="10px" fontWeight="600" color="#B3B3B3">
                    Mints
                  </Text>
                  {sortFilter?.name === "mints" &&
                  sortFilter?.order === "asc" ? (
                    <IconSortAscending
                      color="#B3B3B3"
                      size="14px"
                      onClick={() => {
                        setSortFilter({
                          name: "mints",
                          order: "desc",
                        });
                      }}
                    />
                  ) : sortFilter?.name === "mints" &&
                    sortFilter?.order === "desc" ? (
                    <IconSortDescending
                      color="#B3B3B3"
                      size="14px"
                      onClick={() => {
                        setSortFilter({
                          name: "mints",
                          order: "asc",
                        });
                      }}
                    />
                  ) : (
                    <IconArrowsUpDown
                      color="#B3B3B3"
                      size="14px"
                      onClick={() => {
                        setSortFilter({
                          name: "mints",
                          order: "asc",
                        });
                      }}
                    />
                  )}
                </Flex>
              </Th>
              <Th border="0" py="10px">
                <Flex display="flex" align="center" gap="4px">
                  <Text fontSize="10px" fontWeight="600" color="#B3B3B3">
                    Holders
                  </Text>
                  {sortFilter?.name === "holders" &&
                  sortFilter?.order === "asc" ? (
                    <IconSortAscending
                      color="#B3B3B3"
                      size="14px"
                      onClick={() => {
                        setSortFilter({
                          name: "holders",
                          order: "desc",
                        });
                      }}
                    />
                  ) : sortFilter?.name === "holders" &&
                    sortFilter?.order === "desc" ? (
                    <IconSortDescending
                      color="#B3B3B3"
                      size="14px"
                      onClick={() => {
                        setSortFilter({
                          name: "holders",
                          order: "asc",
                        });
                      }}
                    />
                  ) : (
                    <IconArrowsUpDown
                      color="#B3B3B3"
                      size="14px"
                      onClick={() => {
                        setSortFilter({
                          name: "holders",
                          order: "asc",
                        });
                      }}
                    />
                  )}
                </Flex>
              </Th>
              <Th border="0" py="10px" pe="32px">
                <Flex display="flex" align="center" gap="4px">
                  <Text fontSize="10px" fontWeight="600" color="#B3B3B3">
                    Premine
                  </Text>
                  {sortFilter?.name === "premine" &&
                  sortFilter?.order === "asc" ? (
                    <IconSortAscending
                      color="#B3B3B3"
                      size="14px"
                      onClick={() => {
                        setSortFilter({
                          name: "premine",
                          order: "desc",
                        });
                      }}
                    />
                  ) : sortFilter?.name === "premine" &&
                    sortFilter?.order === "desc" ? (
                    <IconSortDescending
                      color="#B3B3B3"
                      size="14px"
                      onClick={() => {
                        setSortFilter({
                          name: "premine",
                          order: "asc",
                        });
                      }}
                    />
                  ) : (
                    <IconArrowsUpDown
                      color="#B3B3B3"
                      size="14px"
                      onClick={() => {
                        setSortFilter({
                          name: "premine",
                          order: "asc",
                        });
                      }}
                    />
                  )}
                </Flex>
              </Th>
              <Th border="0" py="10px" pe="32px">
                <Flex display="flex" align="center" gap="4px">
                  <Text fontSize="10px" fontWeight="600" color="#B3B3B3">
                    #
                  </Text>
                  {sortFilter?.name === "number" &&
                  sortFilter?.order === "asc" ? (
                    <IconSortAscending
                      color="#B3B3B3"
                      size="14px"
                      onClick={() => {
                        setSortFilter({
                          name: "number",
                          order: "desc",
                        });
                      }}
                    />
                  ) : sortFilter?.name === "number" &&
                    sortFilter?.order === "desc" ? (
                    <IconSortDescending
                      color="#B3B3B3"
                      size="14px"
                      onClick={() => {
                        setSortFilter({
                          name: "number",
                          order: "asc",
                        });
                      }}
                    />
                  ) : (
                    <IconArrowsUpDown
                      color="#B3B3B3"
                      size="14px"
                      onClick={() => {
                        setSortFilter({
                          name: "number",
                          order: "asc",
                        });
                      }}
                    />
                  )}
                </Flex>
              </Th>
              <Th
                border="0"
                py="10px"
                pe="32px"
                position="sticky"
                right={0}
                bg={"#101010"}
              ></Th>
            </Thead>
            <Tbody>
              {sortedRunes()
                ?.slice(offset, limit)
                ?.map((rune: any, index: number) => (
                  <Tr
                    bg={
                      runeCart === rune
                        ? "#0B051B"
                        : index % 2 === 0
                        ? "#191919"
                        : "#101010"
                    }
                    _hover={{
                      bg: "#0B051B",
                    }}
                    onMouseOver={() => {
                      setIsHovered(index);
                    }}
                    onMouseLeave={() => {
                      setIsHovered(-1);
                    }}
                  >
                    <Td border="0" py="4px">
                      <Flex align="center" gap="4px">
                        <Text color="#ADB5BD" fontSize="10px" fontWeight="500">
                          {rune?.spacedRune || ""}
                        </Text>
                        {rune?.spacedRune === "UNCOMMON•GOODS" &&
                          // ||
                          //   rune?.spacedRune === "SUPER•MARIO•COIN" ⭐
                          offset === 0 && (
                            <Tooltip
                              hasArrow
                              fontSize="12px"
                              label="No service fee"
                            >
                              <Box
                                bg="#AC00FD"
                                p="1px 5px"
                                borderRadius="12px 0px"
                                cursor="pointer"
                              >
                                <Text
                                  fontSize="9px"
                                  fontWeight="600"
                                  color="#fff"
                                >
                                  FREE
                                </Text>
                              </Box>
                            </Tooltip>
                          )}
                      </Flex>
                    </Td>
                    <Td border="0" py="4px" w="256px">
                      <Flex flexDir="column" gap="4px">
                        <Slider isReadOnly value={rune?.progress || 0}>
                          <SliderTrack height="8px" borderRadius="6px">
                            <SliderFilledTrack bg="linear-gradient(97deg, #CD23E9 44.31%, #AC00FD 90.84%)" />
                          </SliderTrack>
                          <SliderThumb
                            boxSize={6}
                            bg="#43275A"
                            color="#fff"
                            border="0"
                            _focus={{ border: "0" }}
                          >
                            {rune?.symbol || ""}
                          </SliderThumb>
                        </Slider>
                        <Text fontSize="10px" fontWeight="400" color="#ADB5BD">
                          {rune?.progress || 0}%
                        </Text>
                      </Flex>
                    </Td>
                    <Td border="0" py="4px">
                      <Text color="#ADB5BD" fontSize="10px" fontWeight="500">
                        {Number(rune?.mints)?.toLocaleString() || 0}
                      </Text>
                    </Td>
                    <Td border="0" py="4px">
                      <Text color="#ADB5BD" fontSize="10px" fontWeight="500">
                        {Number(rune?.holders)?.toLocaleString() || 0}
                      </Text>
                    </Td>
                    <Td border="0" py="4px">
                      <Text color="#ADB5BD" fontSize="10px" fontWeight="500">
                        {rune?.preminePercentage || 0}%
                      </Text>
                    </Td>
                    <Td border="0" py="4px">
                      <Text color="#ADB5BD" fontSize="10px" fontWeight="500">
                        {rune?.number || 0}
                      </Text>
                    </Td>
                    <Td
                      border="0"
                      py="10px"
                      position="sticky"
                      right={0}
                      bg={
                        runeCart === rune
                          ? "#0B051B"
                          : isHovered === index
                          ? "#0B051B"
                          : index % 2 === 0
                          ? "#191919"
                          : "#101010"
                      }
                      zIndex="1000"
                    >
                      <Button
                        borderRadius="6px"
                        width="64px"
                        height="34px"
                        p="0"
                        flexDir="column"
                        justifyContent="center"
                        alignItems="center"
                        color="#fff"
                        bg="linear-gradient(97deg, rgba(205, 35, 233, 0.30) 44.31%, rgba(172, 0, 253, 0.30) 90.84%)"
                        _hover={{
                          background:
                            "linear-gradient(97deg, #CD23E9 44.31%, #AC00FD 90.84%)",
                        }}
                        onClick={() => {
                          setSearchByName(rune?.spacedRune || "");
                          setSearchParams(`?rune=${rune?.spacedRune}`);
                          setRuneCart(rune);
                          localStorage.setItem("rune", JSON.stringify(rune));
                          window?.scrollTo(0, 0);
                        }}
                      >
                        <Text
                          fontSize="12px"
                          fontWeight="700"
                          // bg="linear-gradient(97deg, #CD23E9 44.31%, #AC00FD 90.84%)"
                          // bgClip="text"
                        >
                          Mint{" "}
                        </Text>
                      </Button>
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <Text color="#fff" size="20px" textAlign="center">
          No Runes Found
        </Text>
      )}
      <Flex justify="end" align="center" gap="8px">
        <ArrowButton
          icon={<IconChevronLeft color="#EBEBEB" size="24px" />}
          onClick={() => {
            if (offset > 0) {
              setOffset(offset - 10);
              setLimit(limit - 10);
            }
          }}
        />
        <ArrowButton
          icon={<IconChevronRight color="#EBEBEB" size="24px" />}
          onClick={() => {
            if (limit < runes?.length) {
              setOffset(offset + 10);
              setLimit(limit + 10);
            }
          }}
        />
      </Flex>
    </Flex>
  );
}

const ArrowButton = ({ icon, onClick }: any) => {
  return (
    <Button
      borderRadius="4px"
      border="1px solid #362446"
      bg="#43275A"
      boxShadow="245px 302px 109px 0px rgba(59, 32, 157, 0.00), 157px 193px 99px 0px rgba(59, 32, 157, 0.01), 88px 109px 84px 0px rgba(59, 32, 157, 0.05), 39px 48px 62px 0px rgba(59, 32, 157, 0.09), 10px 12px 34px 0px rgba(59, 32, 157, 0.10)"
      backdropFilter="blur(10px)"
      height="32px"
      width="32px"
      p="0"
      _hover={{
        bg: "#43275A",
      }}
      onClick={onClick}
    >
      {icon}
    </Button>
  );
};

export default LaunchpadComponent;
