import { Box, Flex, Heading, Image, useDisclosure } from "@chakra-ui/react";
import Header from "../components/Header";
import Dashboard from "../components/Dashboard";
import HeroInfo from "../assets/images/hero_info.png";
import { useEffect, useState } from "react";
import Holders from "./Holders";
import MyItemsPage from "./MyItems";

function Home() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isBonus,
    onOpen: onBonusOpen,
    onClose: onBonusClose,
  } = useDisclosure();

  const [activeTab, setActiveTab] = useState("map");

  useEffect(() => {
    let ls = localStorage.getItem("isClaimed");
    if (!ls) {
      onBonusOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box px="20px">
      <Header
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        isBonus={isBonus}
        onBonusClose={onBonusClose}
      />
      <Flex mt={{ base: "35px", xl: "50px" }} justify="center">
        <Image src={HeroInfo} alt="gameinfo" maxH="40px" />
      </Flex>
      <Flex gap="12px">
        <Flex
          pos="relative"
          padding="14px"
          bg={activeTab === "map" ? "#1B1B1B" : "transparent"}
          borderRadius="12px"
          align="center"
          justify="center"
          cursor="pointer"
          onClick={() => {
            setActiveTab("map");
          }}
        >
          <Heading color="#C9C9C9" fontSize="16px" fontWeight="600">
            Map
          </Heading>
          <Heading
            pos="absolute"
            top="0"
            filter="blur(14px)"
            color="#C9C9C9"
            fontSize="16px"
            fontWeight="600"
            mt="10px"
          >
            Map
          </Heading>
        </Flex>
        <Flex
          pos="relative"
          padding="14px"
          bg={activeTab === "holders" ? "#1B1B1B" : "transparent"}
          borderRadius="12px"
          align="center"
          justify="center"
          cursor="pointer"
          onClick={() => {
            setActiveTab("holders");
          }}
        >
          <Heading color="#C9C9C9" fontSize="16px" fontWeight="600">
            Holders
          </Heading>
          <Heading
            pos="absolute"
            top="0"
            filter="blur(14px)"
            color="#C9C9C9"
            fontSize="16px"
            fontWeight="600"
            mt="10px"
          >
            Holders
          </Heading>
        </Flex>
        <Flex
          pos="relative"
          padding="14px"
          bg={activeTab === "history" ? "#1B1B1B" : "transparent"}
          borderRadius="12px"
          align="center"
          justify="center"
          cursor="pointer"
          onClick={() => {
            setActiveTab("history");
          }}
        >
          <Heading color="#C9C9C9" fontSize="16px" fontWeight="600">
            History
          </Heading>
          <Heading
            pos="absolute"
            top="0"
            filter="blur(14px)"
            color="#C9C9C9"
            fontSize="16px"
            fontWeight="600"
            mt="10px"
          >
            History
          </Heading>
        </Flex>
      </Flex>
      {activeTab === "map" && <Dashboard onOpen={onOpen} />}
      {activeTab === "holders" && <Holders />}
      {activeTab === "history" && <MyItemsPage onBonusOpen={onBonusOpen} />}
    </Box>
  );
}

export default Home;
