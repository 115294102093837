import { useMutation, useQuery } from "@tanstack/react-query";
import { client } from "./api-client";

export const useGetRunesList = () =>
  useQuery(["getRunesList"], async () => {
    const response = await client(`runes/list`);
    if (response?.msg === "success") {
      return response?.data;
    } else {
      return [];
    }
  });

export const useRunesMint = () => {
  return useMutation(async (data: any) => {
    const response = await client(`runes/mint`, {
      method: "POST",
      data,
    });
    return response;
  });
};


export const useRunesPaymentInfo = () => {
  return useMutation(async ({ id, txid }: any) => {
    const response = await client(`runes/pay?id=${id}&&txid=${txid}`, {
      method: "GET",
    });
    return response;
  });
};