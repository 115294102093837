import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  Image,
  Input,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import batchMint from "../assets/images/batchMint.svg";
import hammerIco from "../assets/images/hammer.png";
import { useRunesMint,useRunesPaymentInfo } from "../utils/runes.api";
import {
  useGetNetworkFee, 
  useSelectRandom,
} from "../utils/sats.api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import LaunchpadComponent from "../components/LaunchpadComponent";
import { IconList, IconMap } from "@tabler/icons-react";
import UEFAListView from "../components/UEFAListView";
import UEFAMapView from "../components/UEFAMapView";
import { getRuneCost } from "../utils/runeMint";
import { getUserUTXO } from "../utils/general.api";

function Launchpad() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isBonus,
    onOpen: onBonusOpen,
    onClose: onBonusClose,
  } = useDisclosure();
  const [isMobile] = useMediaQuery("(max-width: 991px)");

  const [searchByName, setSearchByName] = useState("");
  const [activeFee, setActiveFee] = useState("fast");
  const [customFee, setCustomFee] = useState(20);
  const [repeat, setRepeat] = useState(1);
  const [runeCart, setRuneCart] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<any>(false);
  const [isRuneMint, setIsRuneMint] = useState<any>(false);
  const [satsToMint, setSatsToMint] = useState([]);
  const [activeTab, setActiveTab] = useState("hotMint");
  const [activeView, setActiveView] = useState("List View");

  const { activeAccount, satStatus } = useSelector(
    (state: any) => state.general
  );
  const { data: networkFee } = useGetNetworkFee();

  const randomSats = useSelectRandom();
  const runesMint = useRunesMint();
  const callbackPaymentInfo = useRunesPaymentInfo();

  const getTotalCost = () => {
    const totalCost = getRuneCost(
      repeat,
      activeAccount?.address,
      activeFee === "fast"
        ? networkFee?.data?.fastestFee
        : activeFee === "medium"
        ? networkFee?.data?.halfHourFee
        : customFee,
      runeCart?.spacedRune === "UNCOMMON•GOODS" ? 0 : satStatus?.service_fee
    );
    // console.log("totalCost", totalCost);
    return totalCost;
  };

  const formatNumber = () => {
    const number = Number(runeCart?.amount) * Number(repeat);
    // console.log("--------number", number);
    if (number >= 1e9) {
      return (number / 1e9).toFixed(2) + "B";
    } else {
      return number.toLocaleString();
    }
  };

  const completeTransaction = (txId: any, id: any) => {
    toast.success(`Your TxId is ${txId}`, {
      autoClose: false,
      onClick: () => {
        window.open(`https://mempool.space/tx/${txId}`);
      },
    });
    callbackPaymentInfo
      .mutateAsync({ id: id, txid: txId })
      .then(async (response: any) => {
        setIsLoading(false);
        console.log("---------response2", response);
        toast.success("Your rune has been minted successfully.");
        setRuneCart(null);
        for (let i = 0; i < 6; i++) {
          await new Promise((resolve) => setTimeout(resolve, 5000)); // 5000 milliseconds = 5 seconds
          const response = await callbackPaymentInfo.mutateAsync({
            id: id,
            txid: txId,
          });
        }
      })
      .catch((err: any) => {
        setIsLoading(false);
        console.log("----error3", err);
        toast.warn(err?.message || "Something went wrong. Please try again!");
      });
  };

  useEffect(() => {
    if (isMobile) {
      setActiveView("List View");
    }
  }, [isMobile]);

  // when uncommitting add debounce effect
  // useEffect(() => {
  //   randomSats?.mutateAsync(repeat).then((result) => {
  //     setSatsToMint(result?.data);
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [repeat]);

  useEffect(() => {
    const ls = localStorage.getItem("rune");
    if (ls) {
      setRuneCart(JSON.parse(ls));
    }
  }, []);

  return (
    <Box px="20px">
      <Header
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        isBonus={isBonus}
        onBonusClose={onBonusClose}
      />
      <Flex
        mt={{ base: "25px", xl: "20px" }}
        justify="center"
        maxW={activeView === "Map View" ? "1653px" : "1400px"}
        w="100%"
        mx="auto"
        gap={{ base: "40px", xl: "20px" }}
        flexDir={{ base: "column", xl: "row" }}
      >
        <Flex
          flexDir="column"
          gap="30px"
          maxW={activeView === "List View" ? "980px" : "auto"}
          w="100%"
          mx="auto"
          order={{ base: 2, xl: 1 }}
        >
          <Flex justify="space-between" align="center">
            <Flex gap="12px">
              <Flex
                pos="relative"
                padding="14px"
                bg={activeTab === "hotMint" ? "#1B1B1B" : "transparent"}
                borderRadius="12px"
                align="center"
                justify="center"
                cursor="pointer"
                onClick={() => {
                  setActiveTab("hotMint");
                  setActiveView("List View");
                }}
              >
                <Heading color="#C9C9C9" fontSize="16px" fontWeight="600">
                  Mint Runes
                </Heading>
                <Heading
                  pos="absolute"
                  top="0"
                  filter="blur(14px)"
                  color="#C9C9C9"
                  fontSize="16px"
                  fontWeight="600"
                  mt="10px"
                >
                  Mint Runes
                </Heading>
              </Flex>
              <Flex
                pos="relative"
                padding="14px"
                bg={activeTab === "uefa" ? "#1B1B1B" : "transparent"}
                borderRadius="12px"
                align="center"
                justify="center"
                cursor="pointer"
                onClick={() => {
                  setActiveTab("uefa");
                }}
              >
                {/* <Heading color="#C9C9C9" fontSize="16px" fontWeight="600">
                  UEFA
                </Heading>
                <Heading
                  pos="absolute"
                  top="0"
                  filter="blur(14px)"
                  color="#C9C9C9"
                  fontSize="16px"
                  fontWeight="600"
                  mt="10px"
                >
                  UEFA
                </Heading> */}
              </Flex>
            </Flex>
            {activeTab === "uefa" && !isMobile && (
              <Flex gap="16px" align="center">
                {/* "Map View", */}
                {["List View"].map((item, index) => (
                  <Flex
                    key={index}
                    cursor="pointer"
                    align="center"
                    justify="center"
                    gap="6px"
                    bg={activeView === item ? "#313942" : "transparent"}
                    p="10px"
                    borderRadius="5px"
                    onClick={() => setActiveView(item)}
                  >
                    {index === 0 ? (
                      <IconMap color="#B3B3B3" size="20px" />
                    ) : (
                      <IconList color="#B3B3B3" size="20px" />
                    )}
                    <Text fontSize="12px" color="#B3B3B3" fontWeight="400">
                      {item}
                    </Text>
                  </Flex>
                ))}
              </Flex>
            )}
          </Flex>
          {activeTab === "hotMint" ? (
            <LaunchpadComponent
              setRuneCart={setRuneCart}
              runeCart={runeCart}
              setSearchByName={setSearchByName}
              searchByName={searchByName}
            />
          ) : activeView === "Map View" ? (
            <UEFAMapView
              setRuneCart={setRuneCart}
              runeCart={runeCart}
              setSearchByName={setSearchByName}
              searchByName={searchByName}
            />
          ) : (
            <UEFAListView
              setRuneCart={setRuneCart}
              runeCart={runeCart}
              setSearchByName={setSearchByName}
              searchByName={searchByName}
            />
          )}
        </Flex>
        {activeView === "List View" && (
          <Flex
            flexDir="column"
            gap="30px"
            maxW={{ base: "600px", xl: "400px" }}
            w="100%"
            mt="20px"
            height="100%"
            mx="auto"
            order={{ base: 1, xl: 2 }}
          >
            <Image src={batchMint} alt="mint" height="40px" />
            <Box
              borderRadius="12px"
              border={`1.5px solid ${runeCart ? "#AC00FD" : "#3A3A3A"}`}
              bg="#101010"
              boxShadow="245px 302px 109px 0px rgba(59, 32, 157, 0.00), 157px 193px 99px 0px rgba(59, 32, 157, 0.01), 88px 109px 84px 0px rgba(59, 32, 157, 0.05), 39px 48px 62px 0px rgba(59, 32, 157, 0.09), 10px 12px 34px 0px rgba(59, 32, 157, 0.10)"
              padding="32px 23px"
              height="100%"
            >
              <Flex align="center" gap="8px">
                <Text
                  bg="linear-gradient(97deg, #CD23E9 44.31%, #AC00FD 90.84%)"
                  bgClip="text"
                  fontSize="20px"
                  fontWeight="600"
                >
                  Cart
                </Text>
                {/* <Flex
                align="center"
                justify="center"
                p="10px"
                borderRadius="45px"
                bg="#381E45"
                w="87px"
                color="#C99CFF"
              >
                <Text>23</Text>
              </Flex> */}
              </Flex>
              <Text mt="35px" fontSize="12px" color="#EBEBEB" fontWeight="600">
                Tick
              </Text>
              <Input
                mt="12px"
                border="1px solid #362446"
                background="#251533"
                boxShadow="245px 302px 109px 0px rgba(59, 32, 157, 0.00), 157px 193px 99px 0px rgba(59, 32, 157, 0.01), 88px 109px 84px 0px rgba(59, 32, 157, 0.05), 39px 48px 62px 0px rgba(59, 32, 157, 0.09), 10px 12px 34px 0px rgba(59, 32, 157, 0.10)"
                backdropFilter="blur(10px)"
                padding="14px 24px"
                placeholder="Search Rune"
                _placeholder={{
                  color: "#A2A2A2",
                }}
                fontSize="14px"
                fontWeight="400"
                color="#fff"
                focusBorderColor="transparent"
                _hover={{
                  border: "0",
                }}
                isReadOnly={runeCart?.spacedRune}
                value={searchByName || runeCart?.spacedRune || ""}
                onChange={(e) => setSearchByName(e.target.value)}
              />
              <Flex align="center" gap={"20px"} mt="24px">
                <Text
                  color="#EBEBEB"
                  fontSize="12px"
                  fontWeight="600"
                  display="flex"
                >
                  Repeat{" "}
                  <Text color="#898989" ml="3px">
                    (UTXO Consolidation)
                  </Text>
                </Text>
              </Flex>
              <Flex align="center" gap="10px" mt="5px">
                <Button
                  height="30px"
                  width="50px"
                  onClick={() => {
                    setRepeat(1);
                  }}
                >
                  1
                </Button>
                <Button
                  height="30px"
                  width="50px"
                  onClick={() => {
                    setRepeat(24);
                  }}
                >
                  24
                </Button>
                <Button
                  height="30px"
                  width="50px"
                  onClick={() => {
                    setRepeat(100);
                  }}
                >
                  100
                </Button>
                <Button
                  height="30px"
                  width="50px"
                  onClick={() => {
                    setRepeat(1000);
                  }}
                >
                  1000
                </Button>
              </Flex>
              <Flex mt="12px" justify="space-between" gap="35px" align="center">
                <Slider
                  max={1000}
                  min={1}
                  focusThumbOnChange={false}
                  value={repeat}
                  onChange={(val) => setRepeat(val)}
                >
                  <SliderTrack height="10px" borderRadius="6px">
                    <SliderFilledTrack bg="linear-gradient(97deg, #CD23E9 44.31%, #AC00FD 90.84%)" />
                  </SliderTrack>
                  <SliderThumb boxSize={6} bg="#533685" />
                </Slider>
                <Input
                  type="text"
                  padding="9px 11px"
                  border="1px solid #362446"
                  background="#251533"
                  boxShadow="245px 302px 109px 0px rgba(59, 32, 157, 0.00), 157px 193px 99px 0px rgba(59, 32, 157, 0.01), 88px 109px 84px 0px rgba(59, 32, 157, 0.05), 39px 48px 62px 0px rgba(59, 32, 157, 0.09), 10px 12px 34px 0px rgba(59, 32, 157, 0.10)"
                  backdropFilter="blur(10px)"
                  placeholder="0"
                  max={1000}
                  value={repeat}
                  onChange={(e) => {
                    const value = e.target.value;
                    const sanitizedValue = value.replace(/[^0-9.]/g, "");
                    setRepeat(Number(sanitizedValue));
                  }}
                  _placeholder={{
                    color: "#000",
                  }}
                  p={0}
                  textAlign="center"
                  fontSize="16px"
                  fontWeight="400"
                  color="#fff"
                  focusBorderColor="transparent"
                  _hover={{
                    border: "0",
                  }}
                  maxW="55px"
                />
              </Flex>
              <Text
                fontSize="11px"
                fontFamily="Inter"
                mt="10px"
                color="rgba(255,255,255,0.8)"
              >
                Bulk mint up to 23 linked transactions. Higher repeat mint
                require 2+ blocks.
              </Text>
              <Text mt="35px" color="#ebebeb" fontSize="14px" fontWeight="400">
                Select Network Fee
              </Text>
              <Flex mt="12px" align="center" gap="8px" wrap="wrap">
                <Button
                  bg={
                    activeFee === "medium"
                      ? "transparent"
                      : `rgba(83, 54, 133, 0.70)`
                  }
                  borderRadius="5px"
                  p="10px 15px"
                  width="78px"
                  height="35px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  border={`1px solid  ${
                    activeFee === "medium" ? "#fff" : "transparent"
                  }`}
                  color="#fff"
                  fontSize="12px"
                  fontWeight="400"
                  _hover={{
                    bg: "transparent",
                    border: `1px solid #fff`,
                  }}
                  onClick={() => setActiveFee("medium")}
                >
                  Medium
                </Button>
                <Button
                  bg={
                    activeFee === "fast"
                      ? "transparent"
                      : `rgba(83, 54, 133, 0.70)`
                  }
                  borderRadius="5px"
                  p="10px 15px"
                  width="78px"
                  height="35px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  border={`1px solid  ${
                    activeFee === "fast" ? "#fff" : "transparent"
                  }`}
                  color="#fff"
                  fontSize="12px"
                  fontWeight="400"
                  _hover={{
                    bg: "transparent",
                    border: `1px solid #fff`,
                  }}
                  onClick={() => setActiveFee("fast")}
                >
                  Fast
                </Button>
                <Button
                  bg={
                    activeFee === "custom"
                      ? "transparent"
                      : `rgba(83, 54, 133, 0.70)`
                  }
                  borderRadius="5px"
                  p="10px 15px"
                  width="78px"
                  height="35px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  border={`1px solid  ${
                    activeFee === "custom" ? "#fff" : "transparent"
                  }`}
                  color="#fff"
                  fontSize="12px"
                  fontWeight="400"
                  _hover={{
                    bg: "transparent",
                    border: "1px solid #fff",
                  }}
                  onClick={() => setActiveFee("custom")}
                >
                  Custom
                </Button>
              </Flex>
              {activeFee === "custom" && (
                <>
                  <Slider
                    mt="20px"
                    value={customFee}
                    onChange={(e) => setCustomFee(e)}
                    min={2}
                    max={999}
                    focusThumbOnChange={false}
                  >
                    <SliderTrack height="5px" borderRadius="6px" bg="#533685">
                      <SliderFilledTrack bg="#321F4A" />
                    </SliderTrack>
                    <SliderThumb
                      boxSize={6}
                      p="0"
                      bg="#533685"
                      borderRadius="3px"
                      border="0"
                      height="18px"
                      width="26px"
                      _focus={{ border: "0" }}
                    ></SliderThumb>
                  </Slider>
                  <Flex mt="20px" align="center" gap="20px">
                    <Input
                      border="2px solid rgba(83, 54, 133, 0.40)"
                      type="text"
                      padding="9px 11px"
                      background="transparent"
                      placeholder="0"
                      textAlign="center"
                      _placeholder={{
                        color: "#A2A2A2",
                      }}
                      fontSize="14px"
                      fontWeight="400"
                      color="#fff"
                      focusBorderColor="rgba(83, 54, 133, 0.40)"
                      _hover={{
                        border: "2px solid rgba(83, 54, 133, 0.40)",
                      }}
                      maxW="145px"
                      value={customFee}
                      onChange={(e) => {
                        const value = e.target.value;
                        const sanitizedValue = value.replace(/[^0-9.]/g, "");
                        setCustomFee(Number(sanitizedValue));
                      }}
                    />
                    <Text fontSize="12px" color="#fff" fontWeight="400">
                      {customFee} sats/bytes
                    </Text>
                  </Flex>
                </>
              )}
              <Flex mt="20px" align="center" gap="16px">
                <Flex align="center" gap="8px">
                  <Text color="#fff" fontSize="12px" fontWeight="500">
                    Network Fee:
                  </Text>
                  <Text color="#fff" fontSize="12px" fontWeight="500">
                    {activeFee === "fast"
                      ? networkFee?.data?.fastestFee
                      : activeFee === "medium"
                      ? networkFee?.data?.halfHourFee
                      : customFee}{" "}
                    sats/bytes
                  </Text>
                </Flex>
              </Flex>
              {/* <Flex mt="20px" align="center" gap="16px">
                <Checkbox
                  isChecked={isRuneMint}
                  onChange={(e) => setIsRuneMint(e.target.checked)}
                />
                <Flex
                  align="center"
                  gap="8px"
                  onClick={() => {
                    setIsRuneMint(!isRuneMint);
                  }}
                  cursor="pointer"
                >
                  <Image src={hammerIco} alt="hammer" />
                  <Text color="#fff" fontSize="12px" fontWeight="500">
                    Mine RuneBox
                  </Text>
                </Flex>
              </Flex> */}
              {runeCart && activeAccount?.address && (
                <Flex
                  align="center"
                  gap="8px"
                  mt="20px"
                  justify="space-between"
                >
                  <Text color="#fff" fontSize="12px" fontWeight="500">
                    Total Cost:
                  </Text>
                  <Text color="#fff" fontSize="12px" fontWeight="500">
                    {getTotalCost()?.amount?.toLocaleString()} sats ≈ $
                    {Number(
                      (Number(getTotalCost()?.amount) / 100000000) *
                        Number(satStatus?.btc_price)
                    )?.toFixed(2)}
                  </Text>
                </Flex>
              )}

              <Flex
                gap="10px"
                flexDir={{ base: "column", md: "row" }}
                justify="center"
                align="center"
                mt="12px"
              >
                <Button
                  bg="linear-gradient(97deg, #CD23E9 44.31%, #AC00FD 90.84%)"
                  borderRadius="6px"
                  p="10px"
                  w="254px"
                  h="52px"
                  color="#fff"
                  fontSize="14px"
                  fontWeight="600"
                  _hover={{
                    background: "transparent",
                    border: "2px solid #CD23E9",
                  }}
                  _disabled={{
                    opacity: "0.6",
                  }}
                  isDisabled={runesMint.isLoading || isLoading}
                  isLoading={runesMint.isLoading || isLoading}
                  onClick={async () => {
                    if (!runeCart) {
                      toast.error("Please select a rune to mint");
                      return;
                    }
                    if (Number(repeat) > 1000) {
                      toast.error("You can mint upto 1000 runes at a time");
                      return;
                    }
                    if (activeAccount?.address) {
                      let mintPayload: any = {
                        sendAddress: activeAccount?.address,
                        sendPubkey: activeAccount?.pubKey,
                        // receiveAddress: activeAccount?.address,
                        runeId: runeCart?.runeid,
                        runeName: runeCart?.spacedRune || "",
                        mints: repeat,
                        feeRate:
                          activeFee === "fast"
                            ? networkFee?.data?.fastestFee
                            : activeFee === "medium"
                            ? networkFee?.data?.halfHourFee
                            : customFee,
                        // outputValue: 333,
                      };
                      setIsLoading(true);
                      // const totalCost = getTotalCost();
                      // console.log("totalCost", totalCost);

                      if (isRuneMint) {
                        mintPayload = {
                          ...mintPayload,
                          sats: satsToMint,
                        };
                      }
                      runesMint
                        .mutateAsync({
                          ...mintPayload,
                          sendPubkey: activeAccount?.pubKey,
                        })
                        .then((result) => {
                          if (!result?.data?.psbtHex) {
                            toast.error("Error minting rune");
                            setIsLoading(false);
                            return;
                          }
                          if (activeAccount?.wallet === "Unisat") {
                            window.unisat
                              .signPsbt(result?.data?.psbtHex)
                              .then(async (psbtId: any) => {
                                const txId = await window?.unisat?.pushPsbt(
                                  psbtId
                                );
                                completeTransaction(txId, result?.data?.id);
                              })
                              .catch((err: any) => {
                                setIsLoading(false);
                                toast.warn(
                                  err?.message ||
                                    "Something went wrong, Please try again!"
                                );
                              });
                          } else if (activeAccount?.wallet === "OKX") {
                            window.okxwallet?.bitcoin
                              ?.signPsbt(result?.data?.psbtHex)
                              .then(async (psbtId: any) => {
                                const txId =
                                  await window?.okxwallet?.bitcoin?.pushPsbt(
                                    psbtId
                                  );
                                completeTransaction(txId, result?.data?.id);
                              })
                              .catch((err: any) => {
                                setIsLoading(false);
                                toast.warn(
                                  err?.message ||
                                    "Something went wrong, Please try again!"
                                );
                              });
                          } else {
                            setIsLoading(false);
                            toast.warn(
                              "Something Went wrong, Please try again!"
                            );
                          }
                        })
                        .catch((err) => {
                          console.log("error -- 1", err);
                          setIsLoading(false);
                          toast.error(err?.msg || "Error minting rune");
                        });
                    } else {
                      onOpen();
                    }
                  }}
                >
                  Mint {runeCart ? formatNumber() + " " + runeCart?.symbol : ""}
                </Button>

                <Button
                  borderRadius="6px"
                  bg="transparent"
                  border={{ base: "0", md: "1px solid #CD23E9" }}
                  h="52px"
                  fontSize="14px"
                  fontWeight="600"
                  color={{ base: "#fff", md: "#CD23E9" }}
                  _hover={{
                    background: "#CD23E9",
                    color: "#fff",
                  }}
                  onClick={() => {
                    setSearchByName("");
                    setIsRuneMint(false);
                    setRepeat(1);
                    setCustomFee(20);
                    setActiveFee("fast");
                    setRuneCart(null);
                    localStorage.removeItem("rune");
                  }}
                  isDisabled={runesMint.isLoading || isLoading}
                >
                  Cancel
                </Button>
              </Flex>
            </Box>
          </Flex>
        )}
      </Flex>
    </Box>
  );
}

export default Launchpad;
