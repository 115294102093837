import { Box, useDisclosure } from "@chakra-ui/react";
import HolderPage from "../components/HolderPage";

function Holders() {
  return (
    <Box>
      <HolderPage />
    </Box>
  );
}

export default Holders;
